// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App__wrapper___ehioW {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-ruby-app-simple/src/client/reactComponents/App.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".wrapper {\n    display: flex;\n    min-height: 100vh;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `App__wrapper___ehioW`
};
export default ___CSS_LOADER_EXPORT___;
