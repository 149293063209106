// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermsOfUse__wrapper___P4PhH {
    font-size: 12px;
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["black_100"]};
}

.TermsOfUse__wrapper___P4PhH p {
    margin-bottom: 1.5em;
}

.TermsOfUse__wrapper___P4PhH a {
    text-decoration: underline;
}
.TermsOfUse__wrapper___P4PhH a:hover {
    text-decoration: none;
}
.TermsOfUse__wrapper___P4PhH em {
    font-weight: bold;
}

.TermsOfUse__wrapper___P4PhH h4 {
    font-weight: bold;
    font-size: 20px;
    margin: 40px 0 10px;
}
.TermsOfUse__wrapper___P4PhH h5 {
    font-weight: bold;
    font-size: 16px;
    margin: 20px 0 10px;
}

.TermsOfUse__wrapper___P4PhH ul {
    list-style: disc;
    padding: 0 0 0 16px;
}
.TermsOfUse__wrapper___P4PhH ul li {
    margin: 1.1em 0;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-react-components/src/client/layout/TermsOfUse.cssModule"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,mDAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB","sourcesContent":["@value black_100 from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\n.wrapper {\n    font-size: 12px;\n    color: black_100;\n}\n\n.wrapper p {\n    margin-bottom: 1.5em;\n}\n\n.wrapper a {\n    text-decoration: underline;\n}\n.wrapper a:hover {\n    text-decoration: none;\n}\n.wrapper em {\n    font-weight: bold;\n}\n\n.wrapper h4 {\n    font-weight: bold;\n    font-size: 20px;\n    margin: 40px 0 10px;\n}\n.wrapper h5 {\n    font-weight: bold;\n    font-size: 16px;\n    margin: 20px 0 10px;\n}\n\n.wrapper ul {\n    list-style: disc;\n    padding: 0 0 0 16px;\n}\n.wrapper ul li {\n    margin: 1.1em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black_100": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["black_100"]}`,
	"wrapper": `TermsOfUse__wrapper___P4PhH`
};
export default ___CSS_LOADER_EXPORT___;
