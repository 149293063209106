// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tox .tox-statusbar {
    justify-content: flex-end;
}

.tox .tox-statusbar__resize-handle {
    margin-left: 0;
}

.tox .tox-edit-area__iframe {
    height: 100% !important;
}

.tox-fullscreen .react-grid-item:has(.tox-fullscreen) {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translate(0px, 0px) !important; /* to deal with nested repeater modules */
}

.tox-menu.tox-collection.tox-collection--list {
    max-height: 34vh !important;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-rich-text-editor/src/client/tinymce.skin.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;IAC1B,MAAM;IACN,OAAO;IACP,aAAa;IACb,yCAAyC,EAAE,yCAAyC;AACxF;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["\n.tox .tox-statusbar {\n    justify-content: flex-end;\n}\n\n.tox .tox-statusbar__resize-handle {\n    margin-left: 0;\n}\n\n.tox .tox-edit-area__iframe {\n    height: 100% !important;\n}\n\n.tox-fullscreen .react-grid-item:has(.tox-fullscreen) {\n    position: fixed !important;\n    top: 0;\n    left: 0;\n    z-index: 1000;\n    transform: translate(0px, 0px) !important; /* to deal with nested repeater modules */\n}\n\n.tox-menu.tox-collection.tox-collection--list {\n    max-height: 34vh !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
