// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PropTable__TinyMCE___DgVHB {
    height: 170px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-editor/src/client/reactComponents/PropTable.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".TinyMCE {\n    height: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TinyMCE": `PropTable__TinyMCE___DgVHB`
};
export default ___CSS_LOADER_EXPORT___;
