// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jsoneditor-tree tr > td:first-child > .jsoneditor-dragarea  {
    display:none;
}

.jsoneditor-tree tr.jsoneditor-expandable > td:first-child > .jsoneditor-dragarea  {
    display:block;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-json-editor/src/client/reactComponents/CodeEditor.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".jsoneditor-tree tr > td:first-child > .jsoneditor-dragarea  {\n    display:none;\n}\n\n.jsoneditor-tree tr.jsoneditor-expandable > td:first-child > .jsoneditor-dragarea  {\n    display:block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
