// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!./colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typography__dialog__header_primary___We73I {
    margin-bottom: 0.3em;
}
.typography__dialog__header_secondary___w1Dk6 {
    font-size: 18px;
    font-weight: bold;
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["primary1Color"]};
}
.typography__dialog__header_anchor___PMNbd {
    font-size: 14px;
    float: right;
    margin-top: -18px;
}


.typography__footerCopy___ZtAnn {
}

.typography__link--startUnderlined___bexmY {
    text-decoration: underline;
}
.typography__link--startUnderlined___bexmY:hover {
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-styles/src/client/cssModules/typography.cssModule"],"names":[],"mappings":"AAKA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,mDAAoB;AACxB;AACA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;;AAGA;AACA;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;AACzB","sourcesContent":["@value primary1Color from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\n@value defaultFontSize: 14px;\n\n\n.dialog__header_primary {\n    margin-bottom: 0.3em;\n}\n.dialog__header_secondary {\n    font-size: 18px;\n    font-weight: bold;\n    color: primary1Color;\n}\n.dialog__header_anchor {\n    font-size: 14px;\n    float: right;\n    margin-top: -18px;\n}\n\n\n.footerCopy {\n}\n\n.link--startUnderlined {\n    text-decoration: underline;\n}\n.link--startUnderlined:hover {\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary1Color": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["primary1Color"]}`,
	"defaultFontSize": `14px`,
	"dialog__header_primary": `typography__dialog__header_primary___We73I`,
	"dialog__header_secondary": `typography__dialog__header_secondary___w1Dk6`,
	"dialog__header_anchor": `typography__dialog__header_anchor___PMNbd`,
	"footerCopy": `typography__footerCopy___ZtAnn`,
	"link--startUnderlined": `typography__link--startUnderlined___bexmY`
};
export default ___CSS_LOADER_EXPORT___;
