// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/typography.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer__footer___ARYzG {
    padding: 10px;
}

.Footer__copy___vC9uS {
    font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-header/src/client/reactComponents/Footer.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IAEI,eAAe;AACnB","sourcesContent":[".footer {\n    padding: 10px;\n}\n\n.copy {\n    composes: footerCopy from '@rubyapps/ruby-styles/src/client/cssModules/typography.cssModule';\n    font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer__footer___ARYzG`,
	"copy": `Footer__copy___vC9uS ${___CSS_LOADER_ICSS_IMPORT_0___.locals["footerCopy"]}`
};
export default ___CSS_LOADER_EXPORT___;
