"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
const request = require('@rubyapps/ruby-superagent');
const urljoin = require('url-join');
const Route = require('route-parser');
const rubyLodash = require('@rubyapps/ruby-lodash');
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
const editPageMixin__CONSTANTS = require('@rubyapps/ruby-component-mixin-edit-page/src/common/constants');
function typesWithID(id) {
  return {
    SUBMIT: `@@ruby-app/${id}/SUBMIT`,
    SET_PRISTINE_FORM_DATA: `@@ruby-app/${id}/SET_PRISTINE_FORM_DATA`,
    SEED_CHILDREN: `@@ruby-app/${id}/SEED_CHILDREN`,
    RETRIEVE_FORM: `@@ruby-app/${id}/RETRIEVE_FORM`,
    RESET_STORE: `@@ruby-app/${id}/RESET_STORE`,
    SET_FORCE_UPDATE_TIMESTAMP: `@@ruby-app/${id}/SET_FORCE_UPDATE_TIMESTAMP`,
    SAVE_SUCCESSFUL: `@@ruby-app/${id}/FORM_SAVE_SUCCESSFUL`,
    SAVE_ERROR: `@@ruby-app/${id}/FORM_SAVE_ERROR`
  };
}
const generators = {
  resetStore: function () {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.RESET_STORE
    };
  }
  //# expects selfModule.props.endpoint to be available
  ,
  submitToRemote: function () {
    const selfModule = this;
    const selfModuleEndpoint = selfModule.props.endpoint;
    const thenable = selfModule.props.thenable;
    const {
      feSettingsSelector
    } = this.getDependencies();
    return (_dispatch, getState) => {
      const applicationState = getState();
      const feSettingsState = feSettingsSelector(applicationState);
      const endpointArgs = [feSettingsState.restApiRoot, selfModuleEndpoint];
      const endpoint = urljoin.apply(urljoin, endpointArgs);
      const formValue = selfModule.formValue({
        omitFieldPicker: selfModule.omitInfoAndControlFieldsPicker
      });
      const requestMethod = request.post;
      const requestPromise = requestMethod(endpoint).send(formValue);
      if (thenable) {
        requestPromise.then.apply(requestPromise, thenable);
      }
      requestPromise.catch(err => {
        selfModule.handleErrorObject(err);
        return err;
      });
      return requestPromise;
    };
  }
  //# NOTE: specific for editing model instances
  ,
  saveStateToRemote: function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {/*successCallback, errorCallback*/};
    const selfModule = this;
    const {
      successCallback,
      errorCallback
    } = options;
    if (successCallback) {
      successCallback.__registered = true;
    }
    if (errorCallback) {
      errorCallback.__registered = true;
    }
    const rootModule = selfModule.getRoot();
    const {
      selfAction
    } = this.getDependencies();
    const {
      TYPES,
      generators: actions
    } = selfAction;
    const routeParent = selfModule.findAncestorBy(module => module.getRouteElement);
    return (dispatch, getState) => {
      const route = new Route(selfModule.props.url || selfModule.getSubmitTemplate_fromState(getState()));
      const formValue = selfModule.formValueForRemote();
      const typedPageId = formValue.id ? formValue.id : _lodash.default.result(routeParent, 'getTypedPageId');
      const modelKey = selfModule.props.key;
      dispatch(actions.submitToRoute_withID_andFormValue(route, typedPageId, formValue, [response => {
        const responseBody = response.body;
        const newID = responseBody.id;
        if (typedPageId == undefined) {
          const {
            routeParams: routeParent__routeParams
          } = routeParent.getState();
          const {
            query,
            hash
          } = routeParent__routeParams || {};

          //# NOTE: we do not include query and hash in the routeComponent.prop.path
          //# because the routeComponents themselves should not be bound to either query param or hash
          //# those are auxiliary info that is used by components nested in the route (eg. tabs)
          //# so that means if we want to reconstruct the current path
          //# we need to include query and hash manually

          const newPath = rootModule.getUrlForComponent_fromModule_withParams(routeParent.getID(), routeParent, {
            id: newID,
            action: 'edit'
          });

          //# replace instead of navigate because we don't want to allow
          //# users to go back to the create endpoint
          dispatch(actions.replacePathWithOptions({
            path: newPath,
            query,
            hash
          }));
        }
        successCallback && successCallback(null, responseBody);
        dispatch({
          type: TYPES.SAVE_SUCCESSFUL,
          payload: {
            responseBody,
            route,
            modelKey,
            pageId: newID
          }
        });
        return responseBody;
      }, error => {
        errorCallback && errorCallback(error);
        throw error; //# rethrow error
      }]));
    };
  },

  submitToEndpoint_withID_andFormValue: function (endpoint, id, formValue, thenableArgs) {
    console.warn('[DEPRECATED 20190404 - use `submitToRoute_withID_andFormValue` instead.');
    return this.getAction().generators.submitToRoute_withID_andFormValue(new Route(`${endpoint}(/:id)`), id, formValue, thenableArgs);
  },
  submitToRoute_withID_andFormValue: function (route, id, formValue, thenableArgs) {
    const selfModule = this;
    if (formValue == undefined) {
      formValue = selfModule.formValue({
        omitFieldPicker: selfModule.omitInfoAndControlFieldsPicker
      });
    }
    const {
      selfAction
    } = selfModule.getDependencies();
    const actions = selfAction.generators;
    //# QUESTION: how to traverse the children and collect the data?
    //# can we use rubyComponent.iterativelyTraverse...
    return dispatch => {
      const formValueForServer = _extends({}, formValue, id ? {
        id
      } : {});
      const endpoint = route.reverse({
        id
      });
      const endpointWithId = id && id !== 'create' ?
      // id should never be set to 'create'... but just in case
      urljoin(endpoint, `?url=1`) : endpoint;
      const requestMethod = id ? request.patch : request.post;
      let requestPromise = requestMethod(endpointWithId).send(formValueForServer).then(response => {
        this.clearStatefulCacheForKey('submitToRoute_withID_andFormValue__requestPromise');
        selfModule.pushNotification(rubyNotificationsComponent.notification_savedChanges());

        //# reseed info mode components
        //# NOTE: cannot batch because it's a thunk
        const response__formValue = response.body;
        if (response__formValue) {
          return Promise.fromCallback(callback => {
            dispatch(selfAction.generators.refreshInfoModeFieldsWithFormData(response__formValue, callback));
          }).then(() => {
            const newPristineFormData = _extends({}
            //# NOTE: we previously preferred formValue rather than the data from the response.body
            //# now we're allowing the newer data to overwrite formValue
            //# should be ok since the formValue is the recent data anyways that was used to submit to server
            , formValue
            //# for additional data returned from server, e.g. url
            , response__formValue);

            //# get parent and call on patchState_retrieveFormData_forId
            const parentRouteComponent = selfModule.parentRouteComponent();
            //# if new data
            const isNewRecord = !formValue.id && response__formValue.id;
            if (isNewRecord && parentRouteComponent && parentRouteComponent.patchState_retrieveFormData_forId) {
              parentRouteComponent.patchState_retrieveFormData_forId(response__formValue.id, response);
            }
            dispatch(selfAction.generators.seedWithFormData(newPristineFormData, newPristineFormData, false, () => {
              if (isNewRecord) {
                //# clear loading indicator just in case
                const {
                  loadingIndicatorActions
                } = parentRouteComponent.getDependencies();
                selfModule.getStore().dispatch(loadingIndicatorActions.hide());
              }
            }, {
              omitFieldPicker: (component, formValue, dispatchOrCollect, entireFormValue, options) => {
                //# deep clone formValue cause we need to delete some values
                const componentFieldKey = component.props.key;

                //# diff component.formValue()  and formValue //# but filtered by the kys in component.formValue()
                //# eg. drafts includes a meta property __selectedDraft that won't appear in formValu
                //# so we should exclude that
                //__selectedDraft: {…}, __drafts: Array(1)
                //# NOTE: currentFormValue  might contain more fields than the ingest formValue
                //# so we need to prune it too

                const componentFormValue_clone = _lodash.default.cloneDeep(component.formValue());
                //# NOTE: we technically can pass omitFieldPicker here, but we would also need to update it to account for the formValue as well.

                //# NOTE: this was the previous way we limited currentForm
                //# which relied on component.formValue()
                //# this doesn't work well with components that are dynamically replaced 
                //# (eg. DynamicForm children, Matter Profile) because it relies 
                //# on active local state that might not be available
                const keypathsToOmit = component.limitedSelfKeypaths({
                  deep: true,
                  inverted: true,
                  omitFieldPicker: selfModule.omitInfoAndControlFieldsPicker
                });
                const limitedSelfKeypaths = component.limitedSelfKeypaths({
                  deep: false,
                  inverted: false,
                  omitFieldPicker: selfModule.omitInfoAndControlFieldsPicker
                });
                const limitedKeypaths = limitedSelfKeypaths.length ? limitedSelfKeypaths : component.limitedChildrenKeypaths();
                //# limitedKeypaths might be empty because it's a DynamicForm
                //# for Buttons

                //# formValue_clone is actually {undefined: {...}}  because of leafnode ... we need to extract that
                if (componentFieldKey == undefined && formValue[undefined]) {
                  formValue = formValue[undefined];
                }
                let formValue_clone = _lodash.default.cloneDeep(_lodash.default.pick(formValue, limitedKeypaths)
                //# may be the full form so we need to limit it (eg. if top-level field, this would be full form)
                );

                keypathsToOmit.forEach(keypath => {
                  rubyLodash.deleteBy(componentFormValue_clone, keypath);
                  rubyLodash.deleteBy(formValue_clone, keypath);
                });
                const isSame = _lodash.default.isEqual(componentFormValue_clone, formValue_clone);
                return isSame;
              },
              additionalBatchActions: [actions.setForceUpdateTimestamp(new Date())]
            }));
            return response;
          });
        } else {
          //# response.body is empty in the case of a 204
          return response;
        }
      });
      if (thenableArgs) {
        requestPromise = requestPromise.then.apply(requestPromise, thenableArgs);
      }
      this.setStatefulCacheForKey('submitToRoute_withID_andFormValue__requestPromise', requestPromise);
      return requestPromise.catch(err => {
        this.clearStatefulCacheForKey('submitToRoute_withID_andFormValue__requestPromise');
        selfModule.showErrorNotification({
          error: err
        });
        selfModule.handleErrorObject(err);
        return err;
      });
    };
  },
  setPristineFormData: function (formData) {
    const {
      selfAction: {
        TYPES
      }
    } = this.getDependencies();
    return {
      type: TYPES.SET_PRISTINE_FORM_DATA,
      payload: {
        formData
      }
    };
  }

  /**
   * Just a way to set a pending promisedOnceResolve since seedWithFormData may be called async
   * But dependants need to know it's pending
   **/,
  before_seedWithFormData: function () {
    return dispatch => {
      let promisedOnceResolvedExfil = {
        resolve: null,
        reject: null
      };
      const promisedOnceResolvedPromise = new Promise((resolve, reject) => {
        promisedOnceResolvedExfil.resolve = res => {
          resolve(res);
          setTimeout(() => {
            //# need to delay reenabling form diff
            this.props.enabledFormDiff = true;
          }, 1000);
        };
        promisedOnceResolvedExfil.reject = reject;
      });

      //# hacky but need to disable form diffing while loading
      this.props.enabledFormDiff = false;

      //# set cache
      this.setStatefulCacheForKey('pendingPromisedOnceResolved', promisedOnceResolvedPromise);
      this.setStatefulCacheForKey('promisedOnceResolved', promisedOnceResolvedPromise); //# expose this so dependants can chain to it
      this.setStatefulCacheForKey('pendingPromisedOnceResolvedExfil', promisedOnceResolvedExfil);
    };
  }
  /**
   * @param {Boolean|Object} shouldSetPristineFormData - if object, this would actually be the pristineFormData
   * @param {Object} options - options used to pass into formValueToLocalState.
   * @param {Boolean} options.additionalBatchActions - since save is now using this to update both the edit page field states and pristineFormData, we want to include additional actions as part of batch, includeing setForceUpdateTimestam.
   **/,
  seedWithFormData: function (formData) {
    let shouldSetPristineFormData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let shouldClearForm = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    let callback = arguments.length > 3 ? arguments[3] : undefined;
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    const selfModule = this;
    const {
      selfAction
    } = selfModule.getDependencies();
    const explicitPristineFormData = _lodash.default.isPlainObject(shouldSetPristineFormData) ? shouldSetPristineFormData : false;
    return dispatch => {
      if (shouldClearForm) {
        let clearActionCollector = [];
        const collectClearAction = value => {
          clearActionCollector.push(value);
        };

        //# clear the form
        selfModule.clearForm(collectClearAction);
        dispatch((0, _reduxBatchedActions.batchActions)(clearActionCollector));
      }

      //# return a batch action
      let collector = [];
      const collectAction = function (value) {
        for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          rest[_key - 1] = arguments[_key];
        }
        collector.push(value, ...rest);
      };

      //# passing a promisedOnceResolved promise through formValueToLocalState
      //# as a way to let all children know when the entire promiseTree resolves
      //# this is useful for dynamic components like DynamicForm that may rerender
      //# on form change, BUT it should probably defer any dynamic rerenders/replace children
      //# until AFTER the promiseTree has resolved
      //# OTHERWISE, it interferes with calculating pristineFormData
      //# eg. if the replaceChildren happens mid-pristineFormData calculation, some fields
      //# have null values (since they were unmounted), which is not valid
      let promisedOnceResolvedExfil = this.getStatefulCacheForKey('pendingPromisedOnceResolvedExfil') || {
        resolve: null,
        reject: null
      };
      const promisedOnceResolvedPromise = this.getStatefulCacheForKey('pendingPromisedOnceResolved') || new Promise((resolve, reject) => {
        promisedOnceResolvedExfil.resolve = resolve;
        promisedOnceResolvedExfil.reject = reject;
      });
      this.clearStatefulCacheForKey('pendingPromisedOnceResolvedExfil');
      this.clearStatefulCacheForKey('pendingPromisedOnceResolved');
      if (shouldSetPristineFormData) {
        //# preemptively set pristineFormData so that components have access to it
        dispatch(selfAction.generators.setPristineFormData(explicitPristineFormData || formData));
      }

      //# set cache
      this.setStatefulCacheForKey('promisedOnceResolved', promisedOnceResolvedPromise);
      //# clear cache
      promisedOnceResolvedPromise.then(() => {
        this.clearStatefulCacheForKey('promisedOnceResolved');
      }, () => {
        this.clearStatefulCacheForKey('promisedOnceResolved');
      });
      const promiseTree = selfModule.formValueToLocalState(formData, collectAction, formData, _extends({
        promisedOnceResolved: promisedOnceResolvedPromise
      }, options));
      if (options.additionalBatchActions) {
        collectAction(...options.additionalBatchActions);
      }

      //# set pristine data AFTER seeding so that we can call on formValue()
      //# which will give us the coerced types
      //# we should only do this if the pristineFormData isn't explicit
      //# that is, we expect the pristineFormData to be formData
      //# otherwise, we do not want to coerce the data because we assume it's correct
      //# NOTE: the data coercion has always been hacky due to poorly migrated data
      if (shouldSetPristineFormData && !explicitPristineFormData) {
        promiseTree.then(() => {
          dispatch((0, _reduxBatchedActions.batchActions)(collector)); //# NOTE: need to dispatch AFTER the promiseTree resolves
          selfModule.clearCachedFormValue();
          //# because some sub-promises' resolutions dispatches through the collector()
          //# so we can't actually dispatch the batched actions until all promises resolve

          setImmediate(function () {
            //# allow fields that depend on other fields to resolve their formValue
            const coercedFormValue = _extends({}, formData, selfModule.formValue());

            //# need to ObjectAssign in the passed in formData because the coerced formValue is missing
            //# some meta values

            dispatch(selfAction.generators.setPristineFormData(coercedFormValue));
            callback && callback();
            promisedOnceResolvedExfil.resolve && promisedOnceResolvedExfil.resolve();
            selfModule.updateJsonDiff && selfModule.updateJsonDiff();
          });
        });
      } else {
        promiseTree.then(() => {
          dispatch((0, _reduxBatchedActions.batchActions)(collector)); //# NOTE: need to dispatch AFTER the promiseTree resolves
          selfModule.clearCachedFormValue();
          callback && callback();
          promisedOnceResolvedExfil.resolve && promisedOnceResolvedExfil.resolve();
        });
      }
    };
  },
  seedWithFormError: function (formError) {
    const selfModule = this;
    return dispatch => {
      selfModule.formErrorToLocalState(formError, dispatch);
      //# TODO 20170623: we can probably use module.clearErrorsWithKeys(['message']) now

      //# but right now we need to set all of the field component errors first
      //# then we need to retrieve the errors via the objectValue* methods
      //# then reset the errors
      setImmediate(() => {
        //# setImmediate to make it unblocking
        selfModule.objectErrorToLocalState(selfModule.objectErrorFromLocalState(selfModule.getState()));
      });
    };
  }
  //# After saving, we want to refresh any components whose values are readonly
  //# as they're most likely derived from the server

  /**
   * NOTE: there is an issue with selfModule.formValueToLocalState for fields that call on updateChildren() or replaceChildren() like in the Repeater field
   * @param {Object} options
   * @param {Object} options.omitFieldPicker - (Boolean) function(RubyComponent){} - a function that returns true if
   *     we want to omit the field from being seeded
   */,
  refreshInfoModeFieldsWithFormData(formData, callback) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const selfModule = this;
    const parentEditPageComponent = this.getParentEditPageComponent();
    const omitFieldPicker = options.omitFieldPicker || (node => {
      const isInfoMode = node.props.mode == 'info';
      return !isInfoMode;
    });

    //const infoModeComponents = selfModule.findDescendentsBy(node => node.props.mode == 'info');

    return dispatch => {
      //# return a batch action
      let collector = [];
      const collectAction = value => {
        collector.push(value);
      };
      const promiseTree = selfModule.formValueToLocalState(formData, collectAction, formData, {
        omitFieldPicker
      });
      promiseTree.then(() => {
        dispatch((0, _reduxBatchedActions.batchActions)(collector)); //# NOTE: need to dispatch AFTER the promiseTree resolves
        selfModule.clearCachedFormValue();

        //# let parent edit page know that the form has been updated
        parentEditPageComponent.emit(editPageMixin__CONSTANTS.EVENTS.AFTER_REFRESH_FORM, formData);
        callback && callback();
      });
    };
  },
  setForceUpdateTimestamp: function (timestamp) {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.SET_FORCE_UPDATE_TIMESTAMP,
      payload: {
        timestamp
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};