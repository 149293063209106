"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */
const rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');
const constants = require('../common/constants');

//# NOTE: should not need to have a default state

/**
 *
 *  defaultState = {
 *      errorContext: the context of the error object that the comment dialog should respond to
 *      endpoint: the endpoint to submit the form to
 *      propagateErrorActionType: the action type to dispatch if the error context doesn't match
 *  }
 *
 **/
var defaultState = {
  snackbarConfig: {
    open: false,
    message: '',
    body: [],
    type: null,
    styles: null
    //, type: constants.NOTIFICATION_TYPES.SUCCESS // don't default here or will
    //a flash of success styles when the notifications is leaving
    ,
    customAction: null //{label, onTouchTap}
    ,
    onClose: null,
    additionalActions: null
  },
  modalConfig: {
    open: false,
    modal: true,
    message: '',
    body: [],
    type: null,
    styles: null
    //, type: constants.NOTIFICATION_TYPES.SUCCESS // don't default here or will
    //a flash of success styles when the notifications is leaving
    ,
    customAction: null //{label, onTouchTap}
    ,
    onClose: null,
    additionalActions: null
  },
  snackbarNotificationsQueue: []
};
module.exports = function (state, action) {
  const actionTypes = this.getAction().TYPES;
  if (state === undefined) {
    state = _extends({}, defaultState);
  }
  switch (action.type) {
    case actionTypes.OPEN_NOTIFICATION:
      if (action.payload.modal) {
        return _extends({}, state, {
          modalConfig: _objectSpread(_objectSpread({}, action.payload), {}, {
            open: true
          })
        });
      } else {
        if (state.snackbarConfig.open) {
          return _extends({}, state, {
            snackbarNotificationsQueue: [...state.snackbarNotificationsQueue, action.payload]
          });
        }
        return _extends({}, state, {
          snackbarConfig: _objectSpread(_objectSpread({}, action.payload), {}, {
            open: true
          })
        });
      }
    case actionTypes.CLOSE_NOTIFICATION:
      if (action.modal) {
        return _extends({}, state, {
          modalConfig: _objectSpread(_objectSpread({}, state.modalConfig), {}, {
            open: false
          })
        });
      } else {
        const remainingNotifications = state.snackbarNotificationsQueue.length > 0 ? {
          snackbarConfig: _objectSpread(_objectSpread({}, state.snackbarNotificationsQueue[0]), {}, {
            open: true
          }),
          snackbarNotificationsQueue: state.snackbarNotificationsQueue.slice(1)
        } : {};
        return _extends({}, state, {
          snackbarConfig: _objectSpread(_objectSpread({}, state.snackbarConfig), {}, {
            open: false
          })
        }, remainingNotifications);
      }
    case actionTypes.RESET_NOTIFICATION:
      if (action.modal) {
        return _extends({}, state, {
          modalConfig: defaultState.modalConfig
        });
      } else {
        return _extends({}, state, {
          snackbarConfig: defaultState.snackbarConfig
        });
      }
      return _extends({}, state, defaultState);
    default:
      return state;
  }
};