// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text__wrapper___Y9DLU {
    position: relative;
}

.Text__toolbar___p9E4O {
    position: absolute;
    top: 18px;
    right: 0;
    padding: 4px;
}

.Text__toolbarButton___zo_a8 {
    transform: scale(0.8);
}

.Text__toolbarButtonWrapper___GQWiR {
    display: inline-block;
    margin-right: 10px;
}
.Text__toolbarButtonWrapper___GQWiR:last-child {
    margin-right: 0;
}

.Text__toolbarButton___zo_a8 button .fa {
    font-size: 22px!important;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-auto-populated-text/src/client/reactComponents/Text.cssModule"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".wrapper {\n    position: relative;\n}\n\n.toolbar {\n    position: absolute;\n    top: 18px;\n    right: 0;\n    padding: 4px;\n}\n\n.toolbarButton {\n    transform: scale(0.8);\n}\n\n.toolbarButtonWrapper {\n    display: inline-block;\n    margin-right: 10px;\n}\n.toolbarButtonWrapper:last-child {\n    margin-right: 0;\n}\n\n.toolbarButton button :global(.fa) {\n    font-size: 22px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Text__wrapper___Y9DLU`,
	"toolbar": `Text__toolbar___p9E4O`,
	"toolbarButton": `Text__toolbarButton___zo_a8`,
	"toolbarButtonWrapper": `Text__toolbarButtonWrapper___GQWiR`
};
export default ___CSS_LOADER_EXPORT___;
