// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Toggles__columns___NuzbX {
    display: flex;
    justify-content: space-between;
    /*border: 1px solid rgba(0,0,0,0.2);*/ /*same as textarea*/
    /*padding: 0 15px 15px 15px;*/
}

.Toggles__column___FK0sc {
}

.Toggles__rows___VBEsl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*border: 1px solid rgba(0,0,0,0.2);*/ /*same as textarea*/
    /*padding: 0 15px 15px 15px;*/
}

.Toggles__rowGroup___NS7mv {
    margin-bottom: 10px;
}

.Toggles__rowGroup___NS7mv .Toggles__label___ijVm2 {
    margin-top: 10px;
    line-height: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.Toggles__rowGroup___NS7mv .Toggles__hr___PoJvW {
    border-top: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.Toggles__row___tJeDW {
    display: flex;
    justify-content: space-between;
}

.Toggles__toggle___lN7x0 {
    width: 100%!important;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-toggles/src/client/reactComponents/Toggles.cssModule"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,qCAAqC,EAAE,mBAAmB;IAC1D,6BAA6B;AACjC;;AAEA;AACA;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,qCAAqC,EAAE,mBAAmB;IAC1D,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,wCAAwC;AAC5C;AACA;IACI,gBAAgB;IAChB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["\n\n.columns {\n    display: flex;\n    justify-content: space-between;\n    /*border: 1px solid rgba(0,0,0,0.2);*/ /*same as textarea*/\n    /*padding: 0 15px 15px 15px;*/\n}\n\n.column {\n}\n\n.rows {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    /*border: 1px solid rgba(0,0,0,0.2);*/ /*same as textarea*/\n    /*padding: 0 15px 15px 15px;*/\n}\n\n.rowGroup {\n    margin-bottom: 10px;\n}\n\n.rowGroup .label {\n    margin-top: 10px;\n    line-height: 30px;\n    border-bottom: 1px solid rgba(0,0,0,0.2);\n}\n.rowGroup .hr {\n    border-top: none;\n    border-bottom: 1px solid rgba(0,0,0,0.2);\n}\n\n.row {\n    display: flex;\n    justify-content: space-between;\n}\n\n.toggle {\n    width: 100%!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columns": `Toggles__columns___NuzbX`,
	"column": `Toggles__column___FK0sc`,
	"rows": `Toggles__rows___VBEsl`,
	"rowGroup": `Toggles__rowGroup___NS7mv`,
	"label": `Toggles__label___ijVm2`,
	"hr": `Toggles__hr___PoJvW`,
	"row": `Toggles__row___tJeDW`,
	"toggle": `Toggles__toggle___lN7x0`
};
export default ___CSS_LOADER_EXPORT___;
