"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
var _constants = _interopRequireDefault(require("../../common/constants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
const request = require('@rubyapps/ruby-superagent');
const urljoin = require('url-join');
const url = require('url');
const POLL_INTERVAL = 5000;
function typesWithID(id) {
  return {
    SET_ACTIVITIES_ENDPOINT: `@@ruby-app/${id}/SET_ACTIVITIES_ENDPOINT`,
    SET_ACTIVITIES_LIST_ENDPOINT: `@@ruby-app/${id}/SET_ACTIVITIES_LIST_ENDPOINT`,
    RETRIEVE_ACTIVITY_LIST: `@@ruby-app/${id}/RETRIEVE_ACTIVITY_LIST`,
    SET_ACTIVITY_LIST: `@@ruby-app/${id}/SET_ACTIVITY_LIST`,
    SET_ACTIVITIES_BY_ID_TO_POLL: `@@ruby-app/${id}/SET_ACTIVITIES_BY_ID_TO_POLL`,
    CLEAR_ACTIVITY_ID_FROM_POLL: `@@ruby-app/${id}/CLEAR_ACTIVITY_ID_FROM_POLL`,
    SET_POLL_INTERVAL_ID: `@@ruby-app/${id}/SET_POLL_INTERVAL_ID`,
    SET_ACTIVITY_OBJECT: `@@ruby-app/${id}/SET_ACTIVITY_OBJECT`,
    UPDATE_ACTIVITY_OBJECT: `@@ruby-app/${id}/UPDATE_ACTIVITY_OBJECT`,
    TOGGLE_SHOW_ALL: `@@ruby-app/${id}/TOGGLE_SHOW_ALL`,
    EDIT_NEW_COMMENT: `@@ruby-app/${id}/EDIT_NEW_COMMENT`,
    EDIT_ACTIVITY_COMMENT: `@@ruby-app/${id}/EDIT_ACTIVITY_COMMENT`,
    SAVE_ACTIVITY_COMMENT: `@@ruby-app/${id}/SAVE_ACTIVITY_COMMENT`,
    DELETE_ACTIVITY_COMMENT: `@@ruby-app/${id}/DELETE_ACTIVITY_COMMENT`,
    CLEAR_EDITS: `@@ruby-app/${id}/CLEAR_EDITS`,
    RESET_SHOW_ALL: `@@ruby-app/${id}/RESET_SHOW_ALL`,
    RESET_STORE: `@@ruby-app/${id}/RESET_STORE`
  };
}
const generators = {
  setActivitiesEndpoint: function (endpoint) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ACTIVITIES_ENDPOINT,
      payload: {
        endpoint
      }
    };
  },
  setActivitiesListEndpoint: function (endpoint) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ACTIVITIES_LIST_ENDPOINT,
      payload: {
        endpoint
      }
    };
  },
  refreshActivitiesList: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      selfSelector
    } = this.getDependencies();
    return function (dispatch, getState) {
      let applicationState = getState();
      let selfState = selfSelector(applicationState);
      let {
        activitiesListEndpoint
      } = selfState;
      if (activitiesListEndpoint) {
        dispatch(generators.retrieveActivities_withEndpoint(activitiesListEndpoint));
      }
    };
  },
  retrieveActivities_withEndpoint: function (endpoint) {
    const self = this;
    const url = this.props.url;
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      selfSelector,
      frontendSettingsSelector
    } = this.getDependencies();
    return function (dispatch, getState) {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const {
        restApiRoot
      } = frontendSettingsSelector(applicationState);
      const {
        pollIntervalId
      } = selfState;
      return request.get(endpoint).query({
        filter: JSON.stringify({
          "order": ["created_timestamp DESC"]
        })
      }).preventDuplicateCalls(false).then(function success(response) {
        const data = response.body.data;
        //# check if any of the ids need to be polled

        //# convert data.details to object if needed
        const hydratedData = data.map(datum => {
          if (_lodash.default.isString(datum.details)) {
            let parsedJSON = {};
            try {
              parsedJSON = JSON.parse(datum.details);
            } catch (err) {}
            return _extends({}, datum, {
              details: parsedJSON
            });
          }
          return datum;
        });
        const activitiesByIdToPoll = self.getActivitiesByIdToPoll(hydratedData);
        const activityIdsToPoll = Object.keys(activitiesByIdToPoll);
        const actionsToBatch = [generators.setActivityList(hydratedData)];
        if (activityIdsToPoll.length) {
          actionsToBatch.push(generators.setActivitiesByIdToPoll(activitiesByIdToPoll));

          //# cancel previous poll invertal
          if (pollIntervalId) {
            clearInterval(pollIntervalId);
          }

          //# start polling
          const new_pollIntervalId = setInterval(() => {
            //# hit the individual endpoints
            dispatch(generators.pollActivities());
          }, POLL_INTERVAL);
          actionsToBatch.push(generators.setPollIntervalId(new_pollIntervalId));
        }
        const batchedActions = (0, _reduxBatchedActions.batchActions)(actionsToBatch);
        dispatch(batchedActions);
      }, function error(err) {
        self.showErrorNotification({
          error: err
        });
      });
    };
  },
  setActivityList: function (activities) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ACTIVITY_LIST,
      payload: {
        activities
      }
    };
  },
  setActivitiesByIdToPoll: function (activitiesByIdToPoll) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ACTIVITIES_BY_ID_TO_POLL,
      payload: {
        activitiesByIdToPoll
      }
    };
  },
  clearActivityIdFromPoll: function (activityId) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLEAR_ACTIVITY_ID_FROM_POLL,
      payload: {
        activityId
      }
    };
  },
  setPollIntervalId: function (pollIntervalId) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_POLL_INTERVAL_ID,
      payload: {
        pollIntervalId
      }
    };
  },
  pollActivity: function (activityId) {
    const selfModule = this;
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    const {
      activityEndpointRoute
    } = this.props;
    const {
      frontendSettingsSelector
    } = this.getDependencies();
    return (dispatch, getState) => {
      const applicationState = getState();
      const {
        restApiRoot
      } = frontendSettingsSelector(applicationState);
      const endpoint = urljoin(restApiRoot, activityEndpointRoute.reverse({
        id: activityId
      }));

      //# hit endpoint for activityId
      return request.get(endpoint).then(function success(response) {
        const data = response.body;
        //# hydrate the activity response.body.data.detail
        let hydratedData;
        const dataDetails = _lodash.default.get(data, 'details');
        if (_lodash.default.isString(dataDetails)) {
          let parsedJSON = {};
          try {
            parsedJSON = JSON.parse(dataDetails);
          } catch (err) {}
          hydratedData = _extends({}, datum, {
            details: parsedJSON
          });
        } else {
          hydratedData = data;
        }
        if (!selfModule.shouldPollActivity(hydratedData)) {
          const actionsToBatch = [actions.updateActivityObject(hydratedData), actions.clearActivityIdFromPoll(activityId)];
          const batchedActions = (0, _reduxBatchedActions.batchActions)(actionsToBatch);
          dispatch(batchedActions);
        }
      });
    };
  },
  pollActivities: function () {
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    const selfState = this.getState();
    const {
      activitiesByIdToPoll,
      pollIntervalId
    } = selfState;
    const activityIdsToPoll = Object.keys(activitiesByIdToPoll);
    return (dispatch, getState) => {
      if (!activityIdsToPoll.length) {
        //# if there are no more activitiesByIdToPoll, clear the pollIntervalId
        clearInterval(pollIntervalId);
        return dispatch(actions.setPollIntervalId(null));
      } else {
        //# else
        //#     hit endpoints for each of the activities we still need to poll
        //#     on setActivityObject(), we should also clear it from the activitiesByIdToPoll
        _lodash.default.each(activitiesByIdToPoll, (_value, activityId) => {
          dispatch(actions.pollActivity(activityId));
        });
      }
    };
  },
  setActivityObject: function (activity) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ACTIVITY_OBJECT,
      payload: {
        activity
      }
    };
  }
  // as opposed setActivityObject
  // updateActivityObject will merge the data
  // received from polling (user data is not included
  // in this response - keep the user info we
  // already have)
  //
  // avoid creation of another remote
  // avoid using filters in the query string
  ,
  updateActivityObject: function (activity) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UPDATE_ACTIVITY_OBJECT,
      payload: {
        activity
      }
    };
  },
  resetShowAll: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.RESET_SHOW_ALL
    };
  },
  toggleShowAll: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.TOGGLE_SHOW_ALL
    };
  },
  updateNewComment: function (comment) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.EDIT_NEW_COMMENT,
      payload: {
        comment
      }
    };
  },
  saveNewComment: function (newComment) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      selfSelector,
      editPageSelector
    } = this.getDependencies();
    const {
      successNotifier,
      additionalPayload = {}
    } = options;
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const editPageState = editPageSelector(applicationState);
      const {
        activitiesEndpoint
      } = selfState;
      const activities = this.utils.getActivitiesFromSelfState(selfState);
      const approvalId = _lodash.default.get(editPageState, 'contentApprovalData.id'); //# NOTE: Deprecating 20200911 in favor of drafts
      //# however, for now, we'll need to keep it around
      //# IDEA: add an autopopulated textfield that grabs the draft id from the edit page data (the draft id will exist)

      //const basisVersion = this.utils.getBasisVersionFromActivityList(activities);
      //const versionNumber = basisVersion.versionNumber;

      // TODO: dispatch something before everything
      // to indicate to the user that their action
      // was registered... loader?
      return request.post(activitiesEndpoint).send(_objectSpread({
        comment: newComment,
        approval_id: approvalId
      }, additionalPayload)) //# for backwards compat
      .then(response => {
        let versionNumber = _lodash.default.get(response, 'body.version_num');
        dispatch(generators.refreshActivitiesList());
        dispatch(generators.updateNewComment(''));
        if (successNotifier) {
          successNotifier(response);
        } else {
          this.pushNotification({
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
            message: versionNumber ? `Your comment on Revision ${versionNumber} was added` : 'Your comment was added'
          });
        }
      }, err => {
        this.showErrorNotification({
          err
        });
      });
    };
  },
  saveNewCommentAfterSavingContent: function (newComment) {
    const self = this;
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      selfSelector,
      parentForm
    } = this.getDependencies();
    return (dispatch, getState) => {
      let successCallback = formSaveResponse => {
        let successNotifier = response => {
          let versionNumber = _lodash.default.get(response, 'body.version_num');
          self.pushNotification({
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
            message: 'Your changes were saved with your comment'
          });
        };

        // TODO: dispatch something before everything
        // to indicate to the user that their action
        // was registered... loader?

        // an op hook after page save creates the revision activity
        // postgres - we are guaranteed it has persisted and is available
        // let's update it
        dispatch(generators.saveActivityComment(_constants.default.LATEST_REVISION_ACTIVITY, newComment, {
          successNotifier
        }));

        // clear the new comment
        dispatch(generators.updateNewComment(''));
      };
      dispatch(parentForm.getAction().generators.saveStateToRemote({
        successCallback
      }));
    };
  },
  editActivityComment: function (activityId, comment) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.EDIT_ACTIVITY_COMMENT,
      payload: {
        activityId,
        comment
      }
    };
  },
  clearEdits: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLEAR_EDITS
    };
  },
  resetStore: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.RESET_STORE
    };
  },
  saveActivityComment: function (id, comment) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      selfSelector
    } = this.getDependencies();
    const {
      successNotifier
    } = options;
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const {
        activities,
        activitiesEndpoint
      } = selfState;

      // TODO: dispatch something before everything
      // to indicate to the user that their action
      // was registered... loader?

      return request.post(activitiesEndpoint).send({
        id,
        comment
      }).then(response => {
        let updatedActivity = response.body;
        let versionNumber = updatedActivity.version_num;
        dispatch(generators.refreshActivitiesList());
        dispatch(generators.clearEdits());
        if (successNotifier) {
          successNotifier(response);
        } else {
          this.pushNotification({
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
            message: versionNumber ? `Your comment on Revision ${versionNumber} was updated` : 'Your comment was updated'
          });
        }
      }, err => {
        if (err.status === 403) {
          let author = _lodash.default.get(err, 'response.body.error.details.author', 'another user');
          this.showNotificationModal({
            title: 'Forbidden',
            message: `You may not modify the comments of ${author}.`
          });
        } else {
          this.showErrorNotification({
            err
          });
        }
      });
    };
  },
  deleteCommentActivity: function (id) {
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      selfSelector,
      editPageSelector
    } = this.getDependencies();
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const {
        activitiesEndpoint
      } = selfState;
      const activities = this.utils.getActivitiesFromSelfState(selfState);
      const status = 'deleted';

      // TODO: dispatch something before everything
      // to indicate to the user that their action
      // was registered... loader?

      return request.post(activitiesEndpoint).send({
        id,
        status
      }).then(response => {
        let updatedActivity = response.body;
        let versionNumber = updatedActivity.version_num;

        // filter out the activity in the list of activities
        let updatedActivities = activities.filter(activity => {
          return activity.id !== id;
        });
        dispatch(generators.setActivityList(updatedActivities));
        dispatch(generators.clearEdits());
        this.pushNotification({
          type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
          message: versionNumber ? `Your comment on Revision ${versionNumber} was deleted` : 'Your comment was deleted'
        });
      }, err => {
        if (err.status === 403) {
          let author = _lodash.default.get(err, 'response.body.error.details.author', 'another user');
          this.showNotificationModal({
            title: 'Forbidden',
            message: `You may not delete the comments of ${author}.`
          });
        } else {
          this.showErrorNotification({
            err
          });
        }
      });
    };
  },
  previewActivityVersion: function (activity) {
    const {
      selfSelector,
      editPageSelector,
      contentCompare
    } = this.getDependencies();
    const {
      versionNumber,
      versionDataId
    } = activity;
    return (dispatch, getState) => {
      const applicationState = getState();
      const editPageState = editPageSelector(applicationState);
      const editPageEndpoint = editPageState.instanceDataEndpoint;
      const {
        id,
        template: templateKey
      } = editPageState.routeParams;
      const selfState = selfSelector(applicationState);
      const activities = this.utils.getActivitiesFromSelfState(selfState);
      const basisVersion = this.utils.getBasisVersionFromActivityList(activities);
      const basisVersionDataId = basisVersion.versionDataId;
      const parsedEditPageEndpoint = url.parse(editPageEndpoint);
      let compareURL = urljoin(parsedEditPageEndpoint.pathname, '/compare-versions', `?versionDataId=${versionDataId}&basisVersionDataId=${basisVersionDataId}&${parsedEditPageEndpoint.query}`);
      dispatch(contentCompare.getAction().generators.retrieveReviewData_thenOpen_usingOptions({
        compareURL,
        id: id,
        templateKey: templateKey,
        labels: {
          result: `Revision ${versionNumber}`
        }
      }));
    };
  },
  revertToVersion: function (versionNumber, versionDataId) {
    const {
      selfSelector,
      editPage,
      frontendSettingsSelector,
      editPageSelector
    } = this.getDependencies();
    return (dispatch, getState) => {
      const applicationState = getState();
      const frontendSettings = frontendSettingsSelector(applicationState);
      const editPageState = editPageSelector(applicationState);
      const {
        retrieveFormDataAtEndpoint_thenHydrate
      } = editPage.getAction().generators;
      let revertEndpoint = urljoin(frontendSettings.restApiRoot, 'version_data', versionDataId);
      let revertOptions = {
        responseDataExtractor: response => {
          return JSON.parse(response.body.raw_data);
        },
        successHandler: () => {
          this.pushNotification({
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO,
            message: `Reverted content to Revision ${versionNumber}`
          });
        },
        errorHandler: error => {
          this.showErrorNotification({
            error
          });
        },
        shouldSetPristineFormData: false
      };

      // TODO: dispatch something before everything
      // to indicate to the user that their action
      // was registered... loader?

      dispatch(retrieveFormDataAtEndpoint_thenHydrate(revertEndpoint, revertOptions));
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};