// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__textfield___MimTI {
    vertical-align: bottom;
}

.index__textfield--inactive___cFGSw input {
    color: rgba(0,0,0,0.34)!important;
}
.index__textfield--active___QvS8X{
}


`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-react-components/src/client/input/AutoCompleteSelector/index.cssModule"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iCAAiC;AACrC;AACA;AACA","sourcesContent":[".textfield {\n    vertical-align: bottom;\n}\n\n.textfield--inactive input {\n    color: rgba(0,0,0,0.34)!important;\n}\n.textfield--active{\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textfield": `index__textfield___MimTI`,
	"textfield--inactive": `index__textfield--inactive___cFGSw`,
	"textfield--active": `index__textfield--active___QvS8X`
};
export default ___CSS_LOADER_EXPORT___;
