// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivityList__activityList___elqGW {
    margin-left: 70px;
    width: 66%;
}

.ActivityList__newComment___lGEo7 {
    margin-left: 70px;
    width: 66%;
}

.ActivityList__newCommentTitle___Lb2au {
    padding-top: 15px;
}

.ActivityList__newCommentOptions___VVE4m {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
}

`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-activities/src/client/reactComponents/ActivityList.cssModule"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".activityList {\n    margin-left: 70px;\n    width: 66%;\n}\n\n.newComment {\n    margin-left: 70px;\n    width: 66%;\n}\n\n.newCommentTitle {\n    padding-top: 15px;\n}\n\n.newCommentOptions {\n    margin-top: 10px;\n    margin-bottom: 10px;\n    text-align: right;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityList": `ActivityList__activityList___elqGW`,
	"newComment": `ActivityList__newComment___lGEo7`,
	"newCommentTitle": `ActivityList__newCommentTitle___Lb2au`,
	"newCommentOptions": `ActivityList__newCommentOptions___VVE4m`
};
export default ___CSS_LOADER_EXPORT___;
