// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-rtg-layout {
    width: 100%;
}
.react-rtg-layout .rtg-widget {
    width: 100%;
    padding: 0;
    margin: 0;
}

.react-rtg-layout .rtg-widget.rtg-image-picker .rtg-input .rtg-media {
    width: 265px;
}

.react-rtg-layout .rtg-button.ui-state-default {
    height: auto;
    border: none;
    padding: 0px;
}
.react-rtg-layout .rtg-widget.rtg-image-picker .rtg-input .rtg-image-info {
    float: left;
}

.react.rtg-image-picker .rtg-insert{
    display: none;
}
.react.rtg-image-picker.rtg-no-media .rtg-insert{
    display: block;
}

.rubyImagePickerWidget .rtg-insert {
    margin-bottom: 10px;
}

/* OVERRIDDING ui.theme.css */
.react.rtg-widget.rtg-image-picker h3,
.react.rtg-widget.rtg-image-picker .rtg-image-info ul li {
    color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-image-picker/src/client/reactComponents/ImagePickerWidget.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,UAAU;IACV,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;AACA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA,6BAA6B;AAC7B;;IAEI,cAAc;AAClB","sourcesContent":[".react-rtg-layout {\n    width: 100%;\n}\n.react-rtg-layout .rtg-widget {\n    width: 100%;\n    padding: 0;\n    margin: 0;\n}\n\n.react-rtg-layout .rtg-widget.rtg-image-picker .rtg-input .rtg-media {\n    width: 265px;\n}\n\n.react-rtg-layout .rtg-button.ui-state-default {\n    height: auto;\n    border: none;\n    padding: 0px;\n}\n.react-rtg-layout .rtg-widget.rtg-image-picker .rtg-input .rtg-image-info {\n    float: left;\n}\n\n.react.rtg-image-picker .rtg-insert{\n    display: none;\n}\n.react.rtg-image-picker.rtg-no-media .rtg-insert{\n    display: block;\n}\n\n.rubyImagePickerWidget .rtg-insert {\n    margin-bottom: 10px;\n}\n\n/* OVERRIDDING ui.theme.css */\n.react.rtg-widget.rtg-image-picker h3,\n.react.rtg-widget.rtg-image-picker .rtg-image-info ul li {\n    color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
