// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoResults__noResultsContent___LfiK6 {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    transition: opacity 450ms ease-in-out;
    background: #fff;
}

.NoResults__imageWrapper___cRP4T {
    width: 200px;
    padding: 67px 67px 0 67px;
    opacity: 0.7;
    display: inline-block;
    position: relative;
}
.NoResults__logo___oiNI3 {
    max-width: 200px;
    margin-bottom: 20px;
}
.NoResults__AnnouncementIcon___SXDkH {
    width: 100px!important;
    height: 100px!important;
    position: absolute;
    top: 0;
    right: 0;
}
.NoResults__header___dwe9o {
    font-size: 20px;
}
.NoResults__details___gW2fQ {
    
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-react-components/src/client/view/NoResults.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;AACA;;AAEA","sourcesContent":[".noResultsContent {\n    padding: 20px;\n    width: 100%;\n    box-sizing: border-box;\n    text-align: center;\n    transition: opacity 450ms ease-in-out;\n    background: #fff;\n}\n\n.imageWrapper {\n    width: 200px;\n    padding: 67px 67px 0 67px;\n    opacity: 0.7;\n    display: inline-block;\n    position: relative;\n}\n.logo {\n    max-width: 200px;\n    margin-bottom: 20px;\n}\n.AnnouncementIcon {\n    width: 100px!important;\n    height: 100px!important;\n    position: absolute;\n    top: 0;\n    right: 0;\n}\n.header {\n    font-size: 20px;\n}\n.details {\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noResultsContent": `NoResults__noResultsContent___LfiK6`,
	"imageWrapper": `NoResults__imageWrapper___cRP4T`,
	"logo": `NoResults__logo___oiNI3`,
	"AnnouncementIcon": `NoResults__AnnouncementIcon___SXDkH`,
	"header": `NoResults__header___dwe9o`,
	"details": `NoResults__details___gW2fQ`
};
export default ___CSS_LOADER_EXPORT___;
