// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/field-base.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPicker__wrapper___uMmm8 {
}
.ColorPicker__label___BA3nc {
    padding-top: 16px;
}

.ColorPicker__buttonWrapper___HJgDq {
    display: flex;
    align-items: center;
    min-height: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-color-picker/src/client/reactComponents/ColorPicker.cssModule"],"names":[],"mappings":"AAAA;AAEA;AACA;IAEI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".wrapper {\n    composes: wrapper from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n.label {\n    composes: label from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n    padding-top: 16px;\n}\n\n.buttonWrapper {\n    display: flex;\n    align-items: center;\n    min-height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ColorPicker__wrapper___uMmm8 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["wrapper"]}`,
	"label": `ColorPicker__label___BA3nc ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"buttonWrapper": `ColorPicker__buttonWrapper___HJgDq`
};
export default ___CSS_LOADER_EXPORT___;
