// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__refreshIndicator___RX2SZ {
    display: inline-block;
    position: relative;
}
.styles__refreshDivWrapper___mexWU {
    position: relative;
    width: 100%;
}
.styles__refreshDivContent___JF2N4 {
    width: 100%;
    text-align: center;
    margin-bottom: -15px;
}
.styles__refreshDivContent__tab___KwcLY {
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-mixin-field-dynamic/src/client/styles.cssModule"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".refreshIndicator {\n    display: inline-block;\n    position: relative;\n}\n.refreshDivWrapper {\n    position: relative;\n    width: 100%;\n}\n.refreshDivContent {\n    width: 100%;\n    text-align: center;\n    margin-bottom: -15px;\n}\n.refreshDivContent__tab {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshIndicator": `styles__refreshIndicator___RX2SZ`,
	"refreshDivWrapper": `styles__refreshDivWrapper___mexWU`,
	"refreshDivContent": `styles__refreshDivContent___JF2N4`,
	"refreshDivContent__tab": `styles__refreshDivContent__tab___KwcLY`
};
export default ___CSS_LOADER_EXPORT___;
