// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuggestionList__AddAll___aNTfn button {
    height: 22px !important;
    line-height: 22px !important;
}
.SuggestionList__AddAll___aNTfn button span {
    padding-left: 8px !important;
    padding-right: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-token-tagger/src/client/reactComponents/SuggestionList.cssModule"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,4BAA4B;AAChC;AACA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC","sourcesContent":[".AddAll button {\n    height: 22px !important;\n    line-height: 22px !important;\n}\n.AddAll button span {\n    padding-left: 8px !important;\n    padding-right: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddAll": `SuggestionList__AddAll___aNTfn`
};
export default ___CSS_LOADER_EXPORT___;
