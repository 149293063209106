// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/field-base.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GooglePreview__wrapper___ZkmUx {
}
.GooglePreview__label___TgKcY {
}

.GooglePreview__seoTitle___LrFU0 {
    color: #11c;
    font-size: 16px;
    text-decoration: underline;
}

.GooglePreview__seoLink___F9Out {
    color: #282;
    line-height: 15px;
}

.GooglePreview__seoDesc___uGUID {
    color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-google-preview/src/client/reactComponents/GooglePreview.cssModule"],"names":[],"mappings":"AAAA;AAEA;AACA;AAEA;;AAEA;IACI,WAAW;IACX,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".wrapper {\n    composes: wrapper from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n.label {\n    composes: label from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n\n.seoTitle {\n    color: #11c;\n    font-size: 16px;\n    text-decoration: underline;\n}\n\n.seoLink {\n    color: #282;\n    line-height: 15px;\n}\n\n.seoDesc {\n    color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `GooglePreview__wrapper___ZkmUx ${___CSS_LOADER_ICSS_IMPORT_0___.locals["wrapper"]}`,
	"label": `GooglePreview__label___TgKcY ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"seoTitle": `GooglePreview__seoTitle___LrFU0`,
	"seoLink": `GooglePreview__seoLink___F9Out`,
	"seoDesc": `GooglePreview__seoDesc___uGUID`
};
export default ___CSS_LOADER_EXPORT___;
