// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldsPicker__nodeWrapper___PM1LX {
    margin-right: 10px;
}

.FieldsPicker__toggleWrapper___KLXrD {
    margin-left: 25px;
}

.FieldsPicker__paper___t4Kxf {
    padding: 20px 5px;
    margin-top: 10px;
    margin-bottom: 10px
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-fields-picker/src/client/reactComponents/FieldsPicker.cssModule"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB;AACJ","sourcesContent":[".nodeWrapper {\n    margin-right: 10px;\n}\n\n.toggleWrapper {\n    margin-left: 25px;\n}\n\n.paper {\n    padding: 20px 5px;\n    margin-top: 10px;\n    margin-bottom: 10px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeWrapper": `FieldsPicker__nodeWrapper___PM1LX`,
	"toggleWrapper": `FieldsPicker__toggleWrapper___KLXrD`,
	"paper": `FieldsPicker__paper___t4Kxf`
};
export default ___CSS_LOADER_EXPORT___;
