"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _ConnectorMappersGenerator = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator"));
var _TokenTagger = _interopRequireDefault(require("./TokenTagger"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    mapStateToProps: defaultMapStateToProps
  } = _ConnectorMappersGenerator.default.call(this, _TokenTagger.default);
  const optionsUrl = this.url();
  const modelInfo = selfModule.getModelInfoFromOptionsUrl(optionsUrl);
  function mapStateToProps(state, ownProps) {
    const {
      selfSelector,
      listerElement
    } = selfModule.getDependencies();
    const selfState = selfSelector(state);
    const formObjects = _lodash.default.filter(selfModule.getFormObjectsFromModelInfo(modelInfo), 'allowDynamicCreation');
    const hydratedProps = selfModule.getProps(true);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const selfStateProps_withoutNullValues = _lodash.default.omitBy(hydratedProps, _lodash.default.isNil);
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
    const mappedProps = _extends({}, ownProps, mixinFieldProps, mappedDefaultProps, {
      id: selfModule.getID(),
      fieldKey: hydratedProps.key
    }, selfStateProps_withoutNullValues, {
      searchValue: selfState.searchValue || ''
    }, {
      listerComponent: listerElement
    }, hydratedProps.key ? _lodash.default.pick(selfState.fields[hydratedProps.key], ['value']) : {}, hydratedProps.optionToHTML ? {
      optionToHTML: selfModule.hydratedToHTMLFromProps({
        toHTML: hydratedProps.optionToHTML
      })
    } : {}, formattedError, {
      showCreateEntryButton: ownProps.hasOwnProperty('showCreateEntryButton') ? ownProps.showCreateEntryButton : formObjects.length > 0,
      canCreateEntryWithKey: selfModule.canCreateEntryWithKey.bind(selfModule),
      canCreateSomeEntry: selfModule.canCreateSomeEntry(),
      templateOptions: formObjects.map(form => ({
        value: form.key,
        text: form.name
      }))
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      utilities: {
        createEntryAsync: selfModule.createEntryAsync.bind(selfModule),
        pushNotification: selfModule.pushNotification.bind(selfModule),
        showErrorNotification: selfModule.showErrorNotification.bind(selfModule)
      }
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TokenTagger.default);
}