// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Module__module___sIJCW {
    width: 100%;
    height: 100%;
    background: #e8e8e8;
}

.Module__moduleBox____A1Vp {
    position: relative;
    width: 100%;
    height: 100%;
}
.Module__moduleBoxHoverContainer___iST3M {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(255,255,255,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
.Module__moduleBox____A1Vp:hover .Module__moduleBoxHoverContainer___iST3M {
    opacity: 1;
}

.Module__errorContainer___t0S9N {
    position: relative;
    text-align: center;
    top: 0;
    padding: 10px;
    background: rgba(255,255,255,0.8);
}

.Module__moduleImage___Mlt_k {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.Module__progressContainer___X2CNj {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Module__leftIconContainer___D9wQ_ {
    position: absolute;
    top: 13px;
    left: 17px;
}
.Module__actionButtonsContainer___QGKpj {
    position: absolute;
    top: 0;
    right: 0;
}

`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-module/src/client/reactComponents/Module.cssModule"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,YAAY;IACZ,OAAO;IACP,iCAAiC;IACjC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,oCAAoC;AACxC;AACA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,MAAM;IACN,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ","sourcesContent":[".module {\n    width: 100%;\n    height: 100%;\n    background: #e8e8e8;\n}\n\n.moduleBox {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n.moduleBoxHoverContainer {\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    background: rgba(255,255,255,0.8);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    opacity: 0;\n    transition: opacity 0.2s ease-in-out;\n}\n.moduleBox:hover .moduleBoxHoverContainer {\n    opacity: 1;\n}\n\n.errorContainer {\n    position: relative;\n    text-align: center;\n    top: 0;\n    padding: 10px;\n    background: rgba(255,255,255,0.8);\n}\n\n.moduleImage {\n    width: 100%;\n    height: auto;\n    display: block;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.progressContainer {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.leftIconContainer {\n    position: absolute;\n    top: 13px;\n    left: 17px;\n}\n.actionButtonsContainer {\n    position: absolute;\n    top: 0;\n    right: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module": `Module__module___sIJCW`,
	"moduleBox": `Module__moduleBox____A1Vp`,
	"moduleBoxHoverContainer": `Module__moduleBoxHoverContainer___iST3M`,
	"errorContainer": `Module__errorContainer___t0S9N`,
	"moduleImage": `Module__moduleImage___Mlt_k`,
	"progressContainer": `Module__progressContainer___X2CNj`,
	"leftIconContainer": `Module__leftIconContainer___D9wQ_`,
	"actionButtonsContainer": `Module__actionButtonsContainer___QGKpj`
};
export default ___CSS_LOADER_EXPORT___;
