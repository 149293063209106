// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/toolbar.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter__ToolbarGroup___PSxpb {
    flex-grow: 1;
    margin-left: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-render/src/filter.cssModule"],"names":[],"mappings":"AAAA;IAEI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".ToolbarGroup {\n    composes: ToolbarGroup from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n    flex-grow: 1;\n    margin-left: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToolbarGroup": `filter__ToolbarGroup___PSxpb ${___CSS_LOADER_ICSS_IMPORT_0___.locals["ToolbarGroup"]}`
};
export default ___CSS_LOADER_EXPORT___;
