// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__Root___flq4H {
    margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-list-page/src/client/reactComponents/styles.cssModule"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB","sourcesContent":[".Root {\n    margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": `styles__Root___flq4H`
};
export default ___CSS_LOADER_EXPORT___;
