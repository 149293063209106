// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImagePickerWidget__rtgPhoto___hjE1U {
    width:260px;
    height:180px;
    position:relative;
    overflow:hidden;
}
.ImagePickerWidget__rtgPhoto_noMedia___q7z9w {
    /* webpackIgnore: true */
    background: url(/images/image_picker.gif) no-repeat;
}



.ImagePickerWidget__rtgPhotoImg___kWSMY {
    margin:0 auto;
}
.ImagePickerWidget__rtgPhotoImg_noMedia___bbUUk {
    padding:0;
    display:none;
}


.ImagePickerWidget__rtgIco___iwdeT {
    position:absolute;
    top:-3px;
    right:-5px;
}

.ImagePickerWidget__rtgIco_noMedia___g6BW2 {
    display: none;
}

.ImagePickerWidget__rtgImageInfo____gWmR {
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["black_50"]};
    font-size: 0.857143em;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-image-picker/src/client/reactComponents/ImagePickerWidget.cssModule"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;AACA;IAEI,wBAAwB;IACxB,mDAAmD;AACvD;;;;AAIA;IACI,aAAa;AACjB;AACA;IAEI,SAAS;IACT,YAAY;AAChB;;;AAGA;IACI,iBAAiB;IACjB,QAAQ;IACR,UAAU;AACd;;AAEA;IAEI,aAAa;AACjB;;AAEA;IACI,mDAAe;IACf,qBAAqB;AACzB","sourcesContent":["@value black_50 from \"@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule\";\n\n.rtgPhoto {\n    width:260px;\n    height:180px;\n    position:relative;\n    overflow:hidden;\n}\n.rtgPhoto_noMedia {\n    composes: rtgPhoto;\n    /* webpackIgnore: true */\n    background: url(/images/image_picker.gif) no-repeat;\n}\n\n\n\n.rtgPhotoImg {\n    margin:0 auto;\n}\n.rtgPhotoImg_noMedia {\n    composes: rtgPhotoImg;\n    padding:0;\n    display:none;\n}\n\n\n.rtgIco {\n    position:absolute;\n    top:-3px;\n    right:-5px;\n}\n\n.rtgIco_noMedia {\n    composes: rtgIco;\n    display: none;\n}\n\n.rtgImageInfo {\n    color: black_50;\n    font-size: 0.857143em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black_50": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["black_50"]}`,
	"rtgPhoto": `ImagePickerWidget__rtgPhoto___hjE1U`,
	"rtgPhoto_noMedia": `ImagePickerWidget__rtgPhoto_noMedia___q7z9w ImagePickerWidget__rtgPhoto___hjE1U`,
	"rtgPhotoImg": `ImagePickerWidget__rtgPhotoImg___kWSMY`,
	"rtgPhotoImg_noMedia": `ImagePickerWidget__rtgPhotoImg_noMedia___bbUUk ImagePickerWidget__rtgPhotoImg___kWSMY`,
	"rtgIco": `ImagePickerWidget__rtgIco___iwdeT`,
	"rtgIco_noMedia": `ImagePickerWidget__rtgIco_noMedia___g6BW2 ImagePickerWidget__rtgIco___iwdeT`,
	"rtgImageInfo": `ImagePickerWidget__rtgImageInfo____gWmR`
};
export default ___CSS_LOADER_EXPORT___;
