// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__sticky___bwj5H {
    top: 61px !important;
}

.styles__columnWrapper___IuLrq {
    display: flex;
    justify-content: 'left';
}
.styles__leftColumn___Jw5yr {
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;
    margin-right: 15px;
}
.styles__rightColumn___el5JN {
    color: rgba(0, 0, 0, 0.5);
}
.styles__selectedItemHeader___KTU9s {
    font-size: 14px;
    display: block;
}

.styles__infoList___QFt0F {
    margin-top: 10px;
}
.styles__infoHeader___HEfQg {
    display: block;
    font-size: 14.5px;
}


.styles__controlsContainer___ABbWf {
    width: 100%;
    display: flex;
    justify-content: center;
}

.styles__controlButton___FwiXf {
    width: 100%;
    margin-right: 10px;
}

.styles__controlButton___FwiXf:last-child {
    margin-right: 0px;
}

.styles__lister__dialogBody____ILDs .Lister__DropzoneIndicator {
    /* width: calc(100% - 16px); */ /* To account for scrollbar width but not ideal*/
    margin-top: 0px;
    top: 64px;
}
.styles__lister__dialogBody____ILDs > div {
    margin-top: 3px;
}
.styles__lister__dialogBody____ILDs > div > div {
    margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-token-tagger/src/client/reactComponents/styles.cssModule"],"names":[],"mappings":"AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,kBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,8BAA8B,EAAE,gDAAgD;IAChF,eAAe;IACf,SAAS;AACb;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB","sourcesContent":["@value spacing: 10px;\n\n.sticky {\n    top: 61px !important;\n}\n\n.columnWrapper {\n    display: flex;\n    justify-content: 'left';\n}\n.leftColumn {\n    max-width: 300px;\n    width: 100%;\n    flex-shrink: 0;\n    margin-right: 15px;\n}\n.rightColumn {\n    color: rgba(0, 0, 0, 0.5);\n}\n.selectedItemHeader {\n    font-size: 14px;\n    display: block;\n}\n\n.infoList {\n    margin-top: 10px;\n}\n.infoHeader {\n    display: block;\n    font-size: 14.5px;\n}\n\n\n.controlsContainer {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.controlButton {\n    width: 100%;\n    margin-right: spacing;\n}\n\n.controlButton:last-child {\n    margin-right: 0px;\n}\n\n.lister__dialogBody :global(.Lister__DropzoneIndicator) {\n    /* width: calc(100% - 16px); */ /* To account for scrollbar width but not ideal*/\n    margin-top: 0px;\n    top: 64px;\n}\n.lister__dialogBody > div {\n    margin-top: 3px;\n}\n.lister__dialogBody > div > div {\n    margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacing": `10px`,
	"sticky": `styles__sticky___bwj5H`,
	"columnWrapper": `styles__columnWrapper___IuLrq`,
	"leftColumn": `styles__leftColumn___Jw5yr`,
	"rightColumn": `styles__rightColumn___el5JN`,
	"selectedItemHeader": `styles__selectedItemHeader___KTU9s`,
	"infoList": `styles__infoList___QFt0F`,
	"infoHeader": `styles__infoHeader___HEfQg`,
	"controlsContainer": `styles__controlsContainer___ABbWf`,
	"controlButton": `styles__controlButton___FwiXf`,
	"lister__dialogBody": `styles__lister__dialogBody____ILDs`
};
export default ___CSS_LOADER_EXPORT___;
