"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sitemapConnectorGenerator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _path = _interopRequireDefault(require("path"));
var _constants = _interopRequireDefault(require("@rubyapps/ruby-component-plugin-websites/src/common/constants"));
var _columnDefinitionsGenerator = _interopRequireDefault(require("../columnDefinitionsGenerator"));
var _index = _interopRequireDefault(require("./UtilityBar/index"));
var _indexConnector = _interopRequireDefault(require("./UtilityBar/indexConnector"));
var _filterOptions = _interopRequireDefault(require("../filterOptions"));
var _superagent = _interopRequireDefault(require("superagent"));
var _rubyHierarchyUtils = require("@rubyapps/ruby-hierarchy-utils");
var _header = _interopRequireDefault(require("./header"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); } //const rubyLogger = require('@rubyapps/ruby-logger');
//const logger = rubyLogger.getLogger('ruby-component-sitemap-page:reactComponents/SitemapConnector');
//# Material Components
//# styles == Note: add local styles file when we need to define custom styles
// TODO: we probably want a way to specify column definitions outside of this component
//       so that ruby-component-field-sitemap can be more generic
const normalizedSitemapUtils = require('@rubyapps/normalized-sitemap-utils').default;
const ListPage = require('@rubyapps/ruby-component-list-page');
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
const sitemapUtils = require('../../common/utils');
const GriddleModule = require('@rubyapps/griddle');
const {
  Griddle,
  GriddleRender,
  GriddleDefaultsPlugin,
  GriddleSubgridPlugin,
  GriddleDndPlugin,
  GriddleUtilityBarPlugin,
  GriddleRemotePlugin,
  GriddlePreferencesPlugin,
  GriddleSelectionPlugin,
  GriddleBatchMovePlugin
} = GriddleModule;
const RemotePlugin = GriddleRemotePlugin.RemotePlugin;
const superagentProvider = GriddleRemotePlugin.superagentProvider;
const DndPlugin = GriddleDndPlugin.DndPlugin;
const DefaultsPlugin = GriddleDefaultsPlugin.DefaultsPlugin;
const rubyDateManager = require('@rubyapps/ruby-date');
function sitemapConnectorGenerator() {
  const selfModule = this;
  const selfModuleID = selfModule.getID();
  const {
    selfSelector,
    alterTemplateDialogActions,
    confirmDialogActions,
    currentUserSelector,
    formsSelector,
    feSettingsSelector,
    listerConfigsComponent,
    parentForm,
    saveStateToRemoteAction,
    newPageDialogActions,
    getEditEntryUrl_forId_withTemplateKeyword,
    forms,
    subsite,
    editPageTemplateKey
  } = selfModule.getDependencies();
  const listPage = ListPage.getReactClass.call(selfModule, {
    columnDefinitionsGenerator: _columnDefinitionsGenerator.default.bind(selfModule)
  });
  let {
    staticFilterTags,
    internalFilterTags,
    internalFieldsQuery = {
      fields: [],
      expand: []
    },
    listerConfig
  } = this.getProps ? this.getProps() : selfModule.props;
  listerConfig = listerConfigsComponent.getAugmentedListerConfig(listerConfig, 'sitemap', this);
  function mapStateToProps(state, ownProps) {
    /*
    return {
        plugins: [GriddleSubgridPlugin]
        , data: require('../sampleData')
    };
    */

    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const {
      fieldKey
    } = mixinFieldProps;

    //=======================================//
    const forms = formsSelector(state);
    const feSettings = feSettingsSelector(state);
    selfModule._connectedPrevState = selfState;
    const currentUser = currentUserSelector(state);
    const currentUsername = _lodash.default.get(currentUser, 'username');
    const {
      client: rubyClientId,
      subsite: subsiteId
    } = selfModule.getActiveRubyClientInfo();
    const {
      hierarchical
    } = listerConfig;
    const columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(selfState.griddleState, listerConfig.columns);
    const apiUrlPrefix = _lodash.default.get(feSettings, 'restApiRoot');
    const listApiEndpoint = _path.default.join(apiUrlPrefix, selfModule.props.listApiEndpoint);
    const fields = _lodash.default.map(columns, col => col.key).filter(colKey => colKey);
    const griddleDefaults = DefaultsPlugin(selfModule.getInitialGriddleState_withDefault({
      pageProperties: {
        pageSize: ListPage.CONSTANTS.DEFAULT_PAGE_SIZE,
        sortColumns: []
      }
    }));
    const sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);
    const remoteConfig = {
      url: listApiEndpoint,
      formatRequest: function (req, tableState, griddleState) {
        const {
          page,
          pageSize,
          filter,
          sortColumn,
          sortDirection
        } = tableState;
        const utilityBarProperties = _lodash.default.get(griddleState, 'utilityBarProperties');
        const sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);
        const columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(griddleState, listerConfig.columns);
        const fieldsQueryObject = selfModule.utils.formatRequestPropsForColumns(columns);
        const baseQuery = {
          p: page - 1,
          psize: pageSize,
          search: filter,
          ignoreUserPermissions: utilityBarProperties.sortable_enabled,
          fields: fieldsQueryObject.fields.concat(fields, ['children', 'id']).concat(internalFieldsQuery.fields || []),
          expand: fieldsQueryObject.expand.concat(internalFieldsQuery.expand || [])
        };
        const filterProps = _lodash.default.get(utilityBarProperties, 'dropdownFilters');
        const filterQuery = _lodash.default.map(filterProps, (value, key) => {
          return {
            key,
            value
          };
        }).filter(filterValue => _lodash.default.get(filterValue, 'value') != null);
        if (sitemapValue.result.length) {
          return _superagent.default.post(listApiEndpoint).query({
            ruby_client_fk: rubyClientId,
            content_subsite_fk: subsiteId
          }).send(_extends({}, baseQuery, {
            sitemap: sitemapValue,
            filters: filterQuery
          }));
        } else {
          return null; //# cause griddle to not actually make the network request because there's no point
        }
      },

      formatSuccess: function (resp, tableState) {
        const total = resp.total;
        return {
          page: tableState.page,
          maxPage: parseInt(total / tableState.pageSize, 10) + (total % tableState.pageSize ? 1 : 0),
          totalCount: total,
          data: resp.data
        };
      }
    };
    const remotePluginInstance = RemotePlugin(superagentProvider, remoteConfig);
    const activeRouteComponent = selfModule.getActiveRouteComponent();
    const {
      routeParams = {}
    } = activeRouteComponent ? activeRouteComponent.getState() : {};
    const {
      id: pageID
    } = routeParams;
    const plugins = [].concat(remotePluginInstance, GriddleSubgridPlugin(), griddleDefaults);
    const getUserHasPerm_forItem = function (perm, item) {
      return selfModule.doesUserHavePermission({
        action: perm,
        template: item.templateKey,
        id: item.id,
        subsite: true,
        ruby_client: true,
        model: 'content'
      });
    };
    return _objectSpread({
      plugins,
      apiUrlPrefix,
      columns,
      fieldKey,
      selfState,
      currentUser,
      forms,
      remoteDataArgs: {
        sitemap: sitemapValue
      },
      getUserHasPerm_forItem,
      canUserPublish: () => selfModule.canUserPublish(),
      renderLister: !!currentUser
    }, _lodash.default.mapValues(_lodash.default.pick(selfModule, ['canUserPublish', 'doesUserHavePermission']), (value, key) => value.bind(selfModule)));
  }
  function mapDispatchToProps(dispatch, ownProps) {
    const boundActionCreators = (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch);
    return {
      actions: boundActionCreators,
      delegateActions: {
        saveStateToRemoteAction: (0, _redux.bindActionCreators)(saveStateToRemoteAction, dispatch),
        newPageDialog: (0, _redux.bindActionCreators)(newPageDialogActions, dispatch),
        alterTemplateDialog: (0, _redux.bindActionCreators)(alterTemplateDialogActions, dispatch),
        confirmDialog: (0, _redux.bindActionCreators)(confirmDialogActions, dispatch)
      }
      //# No longer need to define reflectGriddleState here
      //# we want to rely on 'ruby-react-components/src/client/view/Lister.js'
      //, reflectGriddleState: (griddleState, prevGriddleState={}) => {}
    };
  }

  function _startPollingForUrlUpdate(store, events, _recentlyMovedPages, refreshResponse) {
    //# TODO: poll for url in recently Moved pages
    //# if different from refreshResponse, update and call on events.refreshCurrentPageRemoteHandler
    /*
    const { 
        data
        , pageProperties //# currentPage, maxPage, pageSize, sortAscending
    } = store.getState();
     events.refreshCurrentPageRemoteHandler({
        data
        , maxPage
        , page
        , totalCount
    })
     let intervalId;
    let intervalCount = 0;
    */

    const apiUrlPrefix = selfModule.getDependencies().feSettingsComponent.getState().restApiRoot;

    //id=24412&type=content&content_subsite_fk=1
    const batchUrlEndpoint = _path.default.join(apiUrlPrefix, 'content/getBatchURLs?id=');
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    const {
      filters
    } = stateProps;
    const {
      fieldKey
    } = stateProps;
    const debouncedRefresh_usingGriddleCallback = _lodash.default.debounce(callback => {
      selfModule.pushNotification({
        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO,
        message: 'Refreshing Data.'
      });
      callback();
    }, 5000);
    function warnOrSaveAfterUpdate_fromValue_toValue(oldValue, newValue) {
      let {
        saveParams
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      //# for now, if there is a dupe or not-explicit pruned/deleted pages, we warn and don't auto-save
      //# have logic to check if there are "removed" items
      const missingIds = sitemapUtils.getMissingPagesInSitemap_fromSitemap(newValue, oldValue);
      const dupesIds = sitemapUtils.findDupesInSitemapValue(newValue);
      if (missingIds.length || dupesIds.length) {
        let errorMessages = [];
        if (missingIds.length) {
          errorMessages.push('Sitemap field erroneously has missing pages. Saving was stopped to prevent these pages from being deleted.');
          console.error(`===== missingIds found:`, missingIds, '...  you most likely dragged the item into something that is no longer available, causing us to think it has been deleted.');
        }
        if (dupesIds.length) {
          errorMessages.push('Sitemap field erroneously duplicated page entries. Saving was stopped to prevent the sitemap from showing duplicates');
          console.error(`===== dupesIds found:`, dupesIds, '... edgecase drag case where dragging out from child to parent causes a dupe');
        }
        selfModule.showNotificationModal({
          type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
          title: 'Drag and Drop Error',
          message: 'Error encountered on drag and drop. \n' + errorMessages.join(' \n') + ' \nPlease refresh the page and try again.'
        });
        const saveButton = parentForm.findDescendentBy(node => node.componentName == 'rubyComponentFieldButton' && node.props.label == 'Save');
        const disabledSaveAlert = () => {
          selfModule.showNotificationModal({
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
            title: 'Save Disabled',
            message: 'Page saving is disabled due to issues encountered on drag and drop. \n' + 'Please refresh the page to reenable saving.'
          });
        };

        //# TODO: hacky, forcefully override saveStateToRemote to prevent saves until refresh
        //# we leave this here until we can resolve this edgecase
        saveButton.getDependencies().actionForButton = disabledSaveAlert;
        parentForm.getAction().generators.saveStateToRemote = disabledSaveAlert;
        //# force button to rerender
        saveButton.getStore().dispatch(saveButton.getAction().generators.requestRerender());
      } else {
        selfModule.getGriddleProps().events.clearAllRowSelections();
        selfModule.util__save(saveParams);
      }
    }
    const dndPluginInstance = DndPlugin({
      onBeginDrag: () => {
        debouncedRefresh_usingGriddleCallback.cancel();
      },
      onDrop: (store, movedRowId, precedingRowId, followingRowId, parentId, events) => {
        const griddleData = store.getState().get('data').toJS();
        const fieldValue = selfModule.getFieldValue();
        const currentRowById = _lodash.default.get(fieldValue, 'entities.sitemapItem', {});

        //# TODO: this is probably miscalculated/mismanaged because an id being dragged out should hav ebeen removed from the children
        //# might be causing this issue: https://gitlab.intranet.rubensteintech.com/project/ruby-app/-/issues/759
        const newRowById = getNewRowById_fromCurrRowById_andGriddleData(currentRowById, griddleData);
        const currentRootIds = _lodash.default.get(fieldValue, 'result', []);
        const newRootIds = getNewRootIds_currRootIds_fromMovedId_afterId_beforeId_parentId(currentRootIds, movedRowId, precedingRowId, followingRowId, parentId);
        const newFieldValue = {
          entities: {
            sitemapItem: newRowById
          },
          result: newRootIds
        };
        const sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);
        dispatchProps.actions.setFieldValueByKey((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue), fieldKey);
        warnOrSaveAfterUpdate_fromValue_toValue(sitemapValue, newFieldValue, {
          saveParams: {
            successCallback: function (err, responseBody) {
              const _recentlyMovedPages = responseBody[_constants.default.MOVED_PAGES_PROP];

              //# TODO: poll and patch the data
              debouncedRefresh_usingGriddleCallback(events.refreshCurrentPage.bind(null, refreshResponse => {
                _startPollingForUrlUpdate(store, events, _recentlyMovedPages, refreshResponse);
              }));
              debouncedRefresh_usingGriddleCallback.flush(); //# immediately call on it
            },

            errorCallback: function errorCallback(err) {
              //# undo the local field change cause of error
              dispatchProps.actions.setFieldValueByKey(fieldValue, fieldKey);
            }
          }
        });
        return;
        function getNewRootIds_currRootIds_fromMovedId_afterId_beforeId_parentId(currRootIds, movedRowId, after, before, parentId) {
          const rootIdsWithoutMovedId = _lodash.default.filter(currentRootIds, val => val != movedRowId);
          if (parentId != null) {
            //# NOTE: I assume this is when the movedRowId is moved under something, we remove it from the root
            return rootIdsWithoutMovedId;
          } else if (after == null && before == null) {
            //# item was removed
            return rootIdsWithoutMovedId;
          } else {
            const indexOfAfterRow = _lodash.default.findIndex(rootIdsWithoutMovedId, id => id == followingRowId);
            const indexOfBeforeRow = _lodash.default.findIndex(rootIdsWithoutMovedId, id => id == precedingRowId);
            const newIndexFromAfterRow = indexOfAfterRow != -1 && indexOfAfterRow;
            const newIndexFromBeforeRow = indexOfBeforeRow != -1 && indexOfBeforeRow + 1;
            const newIndexOfMovedRow = [newIndexFromAfterRow, newIndexFromBeforeRow].find(val => Number.isInteger(val));
            const newRootIds = newIndexOfMovedRow != null ? [].concat(rootIdsWithoutMovedId.slice(0, newIndexOfMovedRow), movedRowId, rootIdsWithoutMovedId.slice(newIndexOfMovedRow)) : rootIdsWithoutMovedId;
            return newRootIds;
          }
        }
        function getNewRowById_fromCurrRowById_andGriddleData(currRowById, griddleData) {
          // each row in griddle data contains all of it's children, so
          const rowByIdFromGriddle = _lodash.default.mapValues(normalizedSitemapUtils.getFlattenedNestedData_fromNestedData_withChildrenKey(griddleData, 'children'), (row, id) => _lodash.default.pick(row, ['id', 'children']));
          return _extends({}, currentRowById, rowByIdFromGriddle);
        }
      }
    });
    const anyReasonsForDisabledModifySitemap = selfModule.getAnyReasonsForDisabledModifySitemap();
    const filtersFromTemplate = ownProps.listerConfig.filters;
    const filterOptions = !_lodash.default.isEmpty(filtersFromTemplate) ? filtersFromTemplate : (0, _filterOptions.default)(selfModule);
    const filterOptionTemplateChoices = filterOptions[0].options;
    const mergedPropsFromArgument = _extends({
      searchableFields: selfModule.searchableFieldsFor(template => _lodash.default.find(filterOptionTemplateChoices, ['value', template.key]) != undefined)
    }, ownProps, stateProps, dispatchProps);
    //# temporary cache of the props for access by Griddle
    selfModule._cachedPropsForConnector = mergedPropsFromArgument;
    const utilityBar = GriddleUtilityBarPlugin((0, _indexConnector.default)(selfModule, dispatchProps.actions, dispatchProps.delegateActions, filterOptions, anyReasonsForDisabledModifySitemap, () => selfModule._cachedPropsForConnector)(_index.default));
    const plugins = [].concat(utilityBar, GriddleSelectionPlugin(), anyReasonsForDisabledModifySitemap.length > 0 ? [] : dndPluginInstance, ...stateProps.plugins, GriddleBatchMovePlugin({
      onBatchMove: (store, events, movedIds, relativePosition, referenceId) => {
        //# - griddle might only have a subsite of the sitemap data, e.g. bc of pagination
        //# - so we can't build the entire newFieldValue from just griddle state
        //# - need to update fieldvalue based on the changes instead
        const fieldValue = selfModule.getFieldValue();
        const parentId = relativePosition === 'inside' ? referenceId : getParentId_forReferenceId_inValue(referenceId, fieldValue);
        const fieldValueWithoutMovedIds = {
          result: _lodash.default.difference(fieldValue.result, movedIds),
          entities: {
            sitemapItem: _lodash.default.mapValues(fieldValue.entities.sitemapItem, item => _extends({}, item, {
              children: _lodash.default.difference(item.children, movedIds)
            }))
          }
        };
        const newRootIds = parentId ? fieldValueWithoutMovedIds.result : fieldValueWithoutMovedIds.result.reduce((result, curr) => {
          if (curr === referenceId && relativePosition === 'before') {
            result.push(...movedIds);
          }
          result.push(curr);
          if (curr === referenceId && relativePosition === 'after') {
            result.push(...movedIds);
          }
          return result;
        }, []);
        const newSitemapItems = _lodash.default.mapValues(_lodash.default.get(fieldValueWithoutMovedIds, 'entities.sitemapItem'), item => {
          if (item.id !== parentId) {
            return item;
          }
          const newChildren = relativePosition === 'inside' ? item.children.concat(movedIds) : item.children.reduce((result, curr) => {
            if (curr === referenceId && relativePosition === 'before') {
              result.push(...movedIds);
            }
            result.push(curr);
            if (curr === referenceId && relativePosition === 'after') {
              result.push(...movedIds);
            }
            return result;
          }, []);
          return _extends({}, item, {
            children: newChildren
          });
        });
        const newFieldValue = {
          result: newRootIds,
          entities: {
            sitemapItem: newSitemapItems
          }
        };
        const sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);
        dispatchProps.actions.setFieldValueByKey((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue), fieldKey);
        warnOrSaveAfterUpdate_fromValue_toValue(sitemapValue, newFieldValue, {
          saveParams: {
            successCallback: function (err, responseBody) {
              const _recentlyMovedPages = responseBody[_constants.default.MOVED_PAGES_PROP];

              //# TODO: poll and patch the data
              debouncedRefresh_usingGriddleCallback(events.refreshCurrentPage.bind(null, refreshResponse => {
                _startPollingForUrlUpdate(store, events, _recentlyMovedPages, refreshResponse);
              }));
              debouncedRefresh_usingGriddleCallback.flush(); //# immediately call on it
            },

            errorCallback: function errorCallback(err) {
              //# undo the local field change cause of error
              dispatchProps.actions.setFieldValueByKey(fieldValue, fieldKey);
            }
          }
        });
        function getParentId_forReferenceId_inValue(id, value) {
          const rootIds = _lodash.default.get(value, 'result', []);
          if (rootIds.includes(id)) {
            return null;
          }
          const dataById = _lodash.default.get(value, 'entities.sitemapItem', {});
          const parentData = _lodash.default.values(dataById).find(el => el.children && el.children.includes(id));
          return parentData.id;
        }
      }
    })); // TODO: Can we clean up & define the plugins in one place ?

    return _extends({}, ownProps, stateProps, dispatchProps, {
      filters,
      plugins: plugins
      /*
      , headerLabelElement: (
          <ToolbarGroup className={styles.ToolbarGroup_first_baseline_rightMargin}>
              <ToolbarTitle text={stateProps.clientName} className={styles.ToolbarTitle_mainLabel}/>
          </ToolbarGroup>
      )
      */,
      components: {
        Header: _header.default
      }
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(listPage);
}
function getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule) {
  const selfState = selfModule.getState();
  const sitemapValue = _lodash.default.get(selfState, ['fields', fieldKey, 'value']);
  return (0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(sitemapValue);
}