// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("img/plus.gif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("img/plus-over.gif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("img/minus.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* condense styling */
body span.trig, body  span.trig:hover {
  margin: 2px 2px 0 2px;
  display: inline-block;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 2px no-repeat;
  width: 11px;
  cursor: pointer;
}

body span.trig:hover {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) 0 2px no-repeat;
}

body span.trig.open {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) 0 2px no-repeat;
}
.tinyMCE_Condense {
    display: inline;
}
.tinyMCE_Condense p {
    margin-top: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./assets/public/javascript/tiny_mce-custom-plugins/condense/condense.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,qBAAqB;EACrB,qBAAqB;EACrB,mEAA+C;EAC/C,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,mEAAoD;AACtD;;AAEA;EACE,mEAAgD;AAClD;AACA;IACI,eAAe;AACnB;AACA;IACI,2BAA2B;AAC/B","sourcesContent":["/* condense styling */\nbody span.trig, body  span.trig:hover {\n  margin: 2px 2px 0 2px;\n  display: inline-block;\n  background: url('img/plus.gif') 0 2px no-repeat;\n  width: 11px;\n  cursor: pointer;\n}\n\nbody span.trig:hover {\n  background: url('img/plus-over.gif') 0 2px no-repeat;\n}\n\nbody span.trig.open {\n  background: url('img/minus.gif') 0 2px no-repeat;\n}\n.tinyMCE_Condense {\n    display: inline;\n}\n.tinyMCE_Condense p {\n    margin-top: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
