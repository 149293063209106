// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header__header___CM4c9 {
    background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["headerGrey"]};
    position: relative;
    z-index: 16;
}
.Header__Paper___tZLia {
    border-bottom: 1px solid #cecece;
}

.Header__HamburgerIcon___jmucO {
    margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-header/src/client/reactComponents/Header.cssModule"],"names":[],"mappings":"AAEA;IACI,8DAA4B;IAC5B,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@value headerGrey from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\n.header {\n    background-color: headerGrey;\n    position: relative;\n    z-index: 16;\n}\n.Paper {\n    border-bottom: 1px solid #cecece;\n}\n\n.HamburgerIcon {\n    margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerGrey": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["headerGrey"]}`,
	"header": `Header__header___CM4c9`,
	"Paper": `Header__Paper___tZLia`,
	"HamburgerIcon": `Header__HamburgerIcon___jmucO`
};
export default ___CSS_LOADER_EXPORT___;
