// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    font-size: 22px;
}
.SecureLogin__secureLoginGridWrapper___fWU5y {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
}
.SecureLogin__secureLoginBlock___zBE1L {
    text-align: center;
}
.SecureLogin__secureLoginCard___ML09p {
    text-align: left;
}
.SecureLogin__secureLoginHeader___j6N2W {
    text-align: center;
    margin-top: 20px;
}
.SecureLogin__logo___J5maV {
    width: 320px;
    height: 70px;
    display: inline-block;
}

.SecureLogin__refreshIndicator___igHEi {
    display: inline-block!important;
    position: relative!important;
    margin-top: -4px;
}

.SecureLogin__loadingIndicatorWrapper___UpsXl {
    width: 100%;
    text-align: center;
}

.SecureLogin__errorLabel___Xi4NN {
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["errorColor"]};
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-secure-login-page/src/client/reactComponents/SecureLogin.cssModule"],"names":[],"mappings":"AAEA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,+BAA+B;IAC/B,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mDAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["@value errorColor from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\nh1 {\n    font-size: 22px;\n}\n.secureLoginGridWrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n.secureLoginBlock {\n    text-align: center;\n}\n.secureLoginCard {\n    text-align: left;\n}\n.secureLoginHeader {\n    text-align: center;\n    margin-top: 20px;\n}\n.logo {\n    width: 320px;\n    height: 70px;\n    display: inline-block;\n}\n\n.refreshIndicator {\n    display: inline-block!important;\n    position: relative!important;\n    margin-top: -4px;\n}\n\n.loadingIndicatorWrapper {\n    width: 100%;\n    text-align: center;\n}\n\n.errorLabel {\n    color: errorColor;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorColor": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["errorColor"]}`,
	"secureLoginGridWrapper": `SecureLogin__secureLoginGridWrapper___fWU5y`,
	"secureLoginBlock": `SecureLogin__secureLoginBlock___zBE1L`,
	"secureLoginCard": `SecureLogin__secureLoginCard___ML09p`,
	"secureLoginHeader": `SecureLogin__secureLoginHeader___j6N2W`,
	"logo": `SecureLogin__logo___J5maV`,
	"refreshIndicator": `SecureLogin__refreshIndicator___igHEi`,
	"loadingIndicatorWrapper": `SecureLogin__loadingIndicatorWrapper___UpsXl`,
	"errorLabel": `SecureLogin__errorLabel___Xi4NN`
};
export default ___CSS_LOADER_EXPORT___;
