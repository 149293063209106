"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const request = require('@rubyapps/ruby-superagent');
const urljoin = require('url-join');
const moment = require('moment');
const TYPES = {
  RETRIEVE_LOGGED_IN_USER: '@@ruby-app/currentUser/RETRIEVE_LOGGED_IN_USER',
  SET_LOGGED_IN_USER: '@@ruby-app/currentUser/SET_LOGGED_IN_USER',
  CLEAR_LOGGED_IN_USER: '@@ruby-app/currentUser/CLEAR_LOGGED_IN_USER'
};
const CURRENT_USER_INFO_URL = '/ruby/api/v2/users/me';
const CURRENT_USER_INFO_ENDPOINT = '/users/me';
const CURRENT_USER_LOGIN_ENDPOINT = '/users/login';
const CURRENT_USER_LOGOUT_ENDPOINT = '/users/logout';
function _userInfoIsEqual() {
  let userInfoA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let userInfoB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const preppedUserInfoA = _extends({}, _lodash.default.omit(userInfoA, ['created_timestamp']), {
    rubyRole: _lodash.default.omit(_lodash.default.get(userInfoA, 'rubyRole', {}), ['created_timestamp'])
  });
  const preppedUserInfoB = _extends({}, _lodash.default.omit(userInfoB, ['created_timestamp']), {
    rubyRole: _lodash.default.omit(_lodash.default.get(userInfoB, 'rubyRole', {}), ['created_timestamp'])
  });
  return _lodash.default.isEqual(preppedUserInfoA, preppedUserInfoB);
}
const PING_INTERVAL_MS = 1000 * 30 * 1; //# ms * sec * min; //# every 30 sections to mimic the other pollers (frontendSpacesState) 
const EXPIRATION_BUFFER_MS = PING_INTERVAL_MS * 3;
let isPolling = true;
var generators = {
  pollLoggedInUser: function () {
    const selfModule = this;
    const selfActions = selfModule.getAction().generators;
    //# because we only want users/me to handle session timeout

    // since we have retry logic in ruby-superagent, we would only attempt to 
    // retrieve the logged-in user again once the previous request had returned.
    return function (dispatch, getState) {
      dispatch(selfActions.retrieveLoggedInUser(isPolling)).then(() => {
        isPolling = true;
        setTimeout(() => {
          dispatch(selfActions.pollLoggedInUser());
        }, PING_INTERVAL_MS);
      });
    };
  },
  retrieveLoggedInUser: function (isPolling) {
    const self = this;
    const {
      selfActions,
      selfSelector,
      feSettingsSelector
    } = this.getDependencies();
    return function (dispatch, getState) {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const apiUrlPrefix = _lodash.default.get(feSettingsSelector(applicationState), 'restApiRoot');
      const currentPath = _lodash.default.get(applicationState, ['routing', 'locationBeforeTransitions', 'pathname']);
      const endpoint = urljoin(apiUrlPrefix, CURRENT_USER_INFO_ENDPOINT);
      return request.get(endpoint + (isPolling ? '?polling' : '')).then(function success(response) {
        const responseData = _lodash.default.get(response, 'body.data');

        //# NOTE: user info is always not equal because of session
        if (responseData && !_userInfoIsEqual(responseData, selfState)) {
          dispatch(generators.setLoggedInUser(responseData));
          self.showLastLoginDialogIfShould(responseData);
          self.showNewVersionChangelogDialogIfShould(responseData);
        }
        const expirationDate = moment(responseData.__sessionExpires);
        const nowDate = moment();
        if (nowDate.isAfter(expirationDate.subtract(EXPIRATION_BUFFER_MS, 'ms'))) {
          self.showNotificationModal({
            title: 'User Session',
            message: 'Your session is about to expire. Would you like to stay logged in?',
            additionalActions: [{
              label: 'Stay logged in',
              onTouchTap: (evt, callerReactComponent) => {
                callerReactComponent.props.delegateClose(evt, true);
                dispatch(selfActions.retrieveLoggedInUser());
              }
            }]
          });
        }
      }, function error(err) {
        const responseStatus = _lodash.default.get(err, 'response.status');
        //# don't show error, just redirect if necessary
        if (responseStatus && responseStatus != 401) {
          //# only show the error for non-auth related errors
          //self.showErrorNotification({ error: err });
        } else if (currentPath != '/' && currentPath.indexOf('login') < 0 && currentPath.indexOf('reset-password') < 0) {
          self.resetLastLogin();
          //# navigate to /login
          const targetPath = '/login.html?next_page=' + encodeURIComponent(currentPath);
          window.location = targetPath;
        } else {
          //# already in login window, make sure we reset lastLogin
          self.resetLastLogin();
        }
      });
    };
  },
  setIsPolling: function (value) {
    return dispatch => {
      isPolling = value;
    };
  },
  setLoggedInUser: function (userInfo) {
    return {
      type: TYPES.SET_LOGGED_IN_USER,
      payload: {
        userInfo: userInfo
      }
    };
  },
  clearLoggedInUser: function () {
    return {
      type: TYPES.CLEAR_LOGGED_IN_USER
    };
  },
  logIn: function (credentials, thenableArguments) {
    const self = this;
    const {
      selfActions,
      feSettingsSelector
    } = this.getDependencies();
    return function (dispatch, getState) {
      const applicationState = getState();
      const apiUrlPrefix = _lodash.default.get(feSettingsSelector(applicationState), 'restApiRoot');
      const endpoint = urljoin(apiUrlPrefix, CURRENT_USER_LOGIN_ENDPOINT);
      const loginRequestPromise = request.post(endpoint).send(credentials).then(function success(response) {
        //# response contains the accessToken
        dispatch(selfActions.retrieveLoggedInUser());
      });
      if (thenableArguments) {
        loginRequestPromise.then.apply(loginRequestPromise, thenableArguments);
      }
      return loginRequestPromise;
    };
  },
  logOut: function () {
    const self = this;
    const {
      selfActions,
      feSettingsSelector
    } = this.getDependencies();
    const rootModule = this.getRoot();
    const loginPageComponent = rootModule.findDescendentBy(element => element.componentName == 'rubyComponentLoginPage');
    const loginRoute = loginPageComponent.getRoutePath();
    return function (dispatch, getState) {
      const applicationState = getState();
      const apiUrlPrefix = _lodash.default.get(feSettingsSelector(applicationState), 'restApiRoot');
      const endpoint = urljoin(apiUrlPrefix, CURRENT_USER_LOGOUT_ENDPOINT);
      return request.post(endpoint).then(function success(response) {
        window.location.href = loginRoute; //# a hard reroute
        /*
        dispatch(generators.clearLoggedInUser()); //# don't need to clear user unless we dynamically reroute the user
        dispatch(selfActions.navigateToPathWithOptions({
            path: loginRoute
        }));
        */
      }, function error(err) {
        //# we can still clear out logged in user and go to login page 
        window.location.href = loginRoute; //# a hard reroute
        /*
        dispatch(generators.clearLoggedInUser()); //# don't need to clear user unless we dynamically reroute the user
        dispatch(selfActions.navigateToPathWithOptions({
            path: loginRoute
        }));
        */
      });
    };
  },

  clearLocalPreferences: function () {
    return dispatch => {
      localStorage.clear();
    };
  }
};
module.exports = {
  TYPES: TYPES,
  generators: generators
};