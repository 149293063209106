// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InternationalAddress__columnWrapper___OPcAq {
    display: flex;
}
.InternationalAddress__leftColumn___zBvB_ {
    width: 300px;
    margin-right: 20px;
}
.InternationalAddress__rightColumn___wbBCr {
    flex: 1;
}

.InternationalAddress__placeholderWrapper___Gb4jz {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.InternationalAddress__placeholderContent___gI3mY {
    width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-international-address/src/client/reactComponents/InternationalAddress.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,OAAO;AACX;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd","sourcesContent":[".columnWrapper {\n    display: flex;\n}\n.leftColumn {\n    width: 300px;\n    margin-right: 20px;\n}\n.rightColumn {\n    flex: 1;\n}\n\n.placeholderWrapper {\n    height: 100%;\n    width: 100%;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.placeholderContent {\n    width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnWrapper": `InternationalAddress__columnWrapper___OPcAq`,
	"leftColumn": `InternationalAddress__leftColumn___zBvB_`,
	"rightColumn": `InternationalAddress__rightColumn___wbBCr`,
	"placeholderWrapper": `InternationalAddress__placeholderWrapper___Gb4jz`,
	"placeholderContent": `InternationalAddress__placeholderContent___gI3mY`
};
export default ___CSS_LOADER_EXPORT___;
