// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecentUpdates__recentUpdatesList___Xgpuj {
    padding: 5px 20px 20px 20px;
    box-sizing: border-box;
    height: calc(100% - 56px);
    overflow-y: auto;
    overflow-x: hidden;
}


.RecentUpdates__loadingWrapper___egcvu {
    position: absolute;
    top: 70px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-recent-updates/src/client/reactComponents/RecentUpdates.cssModule"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".recentUpdatesList {\n    padding: 5px 20px 20px 20px;\n    box-sizing: border-box;\n    height: calc(100% - 56px);\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n\n\n.loadingWrapper {\n    position: absolute;\n    top: 70px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recentUpdatesList": `RecentUpdates__recentUpdatesList___Xgpuj`,
	"loadingWrapper": `RecentUpdates__loadingWrapper___egcvu`
};
export default ___CSS_LOADER_EXPORT___;
