"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const {
  normalize,
  Schema,
  arrayOf
} = require('normalizr');
const PROPOSAL_SUBSITE_ID = 2;
const {
  PERMISSION_ACTIONS
} = require('@rubyapps/ruby-access-manager/src/common/constants');
function getAutoPopulatedPerms_fromTemplates_andPermItems(templates, permissionItems, selfModule) {
  const valuesByKeyword = getPermissionAclValuesByKeyword_fromTemplates(templates, selfModule);
  const {
    dynamicPermissionsByKey,
    childrenByKeyword
  } = getDynamicPermissions_fromTemplates(templates, selfModule);
  const mergedPermissions = _extends({}, permissionItems, dynamicPermissionsByKey);
  const retVal = getAutoPopulatedPerms_fromMergedPerms_childrenByKeyword_andValuesByKeyword(mergedPermissions, childrenByKeyword, valuesByKeyword);
  return retVal;
}
function getAutoPopulatedPerms_fromMergedPerms_childrenByKeyword_andValuesByKeyword(permissions, childrenByKeyword, valuesByKeyword) {
  return _.mapValues(permissions, item => {
    const children = [].concat(item.children, childrenByKeyword[item.populateWithChildrenFor]).filter(child => child != null);
    const baseItemValue = item.value ? _.castArray(item.value) : [];
    const value = children.length !== 0 ? item.value : baseItemValue.concat(valuesByKeyword[item.populateWithValueFor]).filter(val => val != null);
    return _extends({}, item, {
      children,
      value
    });
  });
}
function getDynamicPermissions_fromTemplates(templates, selfModule) {
  const {
    permissionsByKey: sitePermissionsByKey
  } = getSitemapPerms_fromTemplates(...arguments);
  const {
    structuredDataPermissionsByKey,
    structuredDataPermissionKeys
  } = getSDPerms_fromTemplates(...arguments);
  const {
    permissionsByKey: lookupPermissionsByKey,
    permissionKeys: lookupPermissionKeys
  } = getPerms_fromTemplates_forType(templates, 'lookup_template', selfModule);
  const childrenByKeyword = {
    'structured_data': structuredDataPermissionKeys,
    'lookups': lookupPermissionKeys
    //# NOTE: that modify_sitemap is DEPRECATED in favor of the Spaces plugin's __modify_sitempa permission
  };

  const dynamicPermissionsByKey = _extends({}, sitePermissionsByKey, structuredDataPermissionsByKey, lookupPermissionsByKey);
  return {
    childrenByKeyword,
    dynamicPermissionsByKey
  };
}
function getPermissionAclValuesByKeyword_fromTemplates(templates, selfModule) {
  return {
    template: getTemplatePermValue_fromTemplates(templates)
    //, sitemap: getSitemapPermValue_fromTemplates(templates)
    // , modify_sitemap: getModifySitemapPermValue_fromTemplates(templates) //# DEPRECATED 20250304 - we have the spaces plugin now
    //, lookups:  getLookupPermValue_fromTemplates(templates) //# DEPRECATED - should rely on auto-determined permissionItemsByKey
    ,
    proposals: getProposalPermValue_fromTemplates(templates, selfModule),
    edit_proposals: getEditProposalPermValue_fromTemplates(templates, selfModule),
    generate_proposals: getGenerateProposalPermValue_fromTemplates(templates, selfModule)
  };
}
function getTemplatePermValue_fromTemplates(templates) {
  return [].concat({
    model: 'template',
    ruby_client: 3,
    action: '*'
  }, templates.map(template => {
    return {
      model: 'template',
      template: template.id.toString(),
      ruby_client: 3,
      subsite: 1,
      action: '*'
    };
  }));
}
function getSitemapPermValue_fromTemplates(templates) {
  return [].concat({
    keyword: 'content_sitemap_section',
    ruby_client: 3,
    action: '*'
  }, [].concat.apply([], templates.filter(template => template.templateType === 'sitemap_template').map(template => ({
    model: 'content',
    template: template.id.toString(),
    ruby_client: 3,
    subsite: 1,
    action: '*'
  }))));
}
function getModifySitemapPermValue_fromTemplates(templates) {
  return [].concat({
    keyword: 'modify_sitemap',
    ruby_client: 3,
    action: '*'
  }, [].concat.apply(templates.filter(template => template.templateType === 'sitemap_template').map(template => {
    return {
      model: 'content',
      template: template.id.toString(),
      ruby_client: 3,
      subsite: 1,
      action: '*'
    };
  })));
}
function getLookupPermValue_fromTemplates(templates) {
  return [].concat({
    keyword: 'admin_lookups_section',
    ruby_client: 3,
    action: '*'
  }, [].concat.apply(templates.filter(template => template.templateType === 'lookup_template').map(template => {
    //# TODO: also need to expand here
    return {
      model: 'content',
      template: template.id.toString(),
      ruby_client: 3,
      subsite: 1,
      action: '*'
    };
  })));
}
function getProposalPermValue_fromTemplates(templates, selfModule) {
  const proposalRoute = '/app/proposal';
  const proposalClientInfo = selfModule.getRubyClientInfo_forRoute(proposalRoute);
  const proposalSubsiteId = proposalClientInfo.subsite;
  const proposalClientId = proposalClientInfo.client;
  return [].concat({
    // allow saving wihout error to telling user to send request for approval
    keyword: 'can_generate_proposal_doc',
    subsite: proposalSubsiteId,
    ruby_client: proposalClientId,
    action: '*'
  }, {
    keyword: 'hydrate_sitemap',
    ruby_client: proposalClientId,
    action: '*'
  }, {
    // allow saving wihout error to telling user to send request for approval
    keyword: 'can_publish_content',
    subsite: proposalSubsiteId,
    ruby_client: proposalClientId,
    action: '*'
  }
  //# TODO: find the proposal template
  //# TODO: find all propopsal page templates
  //# TODO: find all templates that can ad to proposal
  , [].concat.apply(templates.filter(template => {
    return template.key == 'proposal' || template.templateType == 'proposal_page_template' || template.ruby_subsite_fk == null || _.isArray(template.ruby_subsite_fk) && template.ruby_subsite_fk.length === 0 || _.isArray(template.ruby_subsite_fk) && _.includes(template.ruby_subsite_fk, PROPOSAL_SUBSITE_ID);
  }).map(template => {
    return {
      model: 'content',
      template: template.id.toString(),
      ruby_client: 3,
      subsite: proposalSubsiteId,
      action: '*'
    };
  })));
}
function getEditProposalPermValue_fromTemplates(templates, selfModule) {
  const proposalRoute = '/app/proposal';
  const proposalClientInfo = selfModule.getRubyClientInfo_forRoute(proposalRoute);
  const proposalSubsiteId = proposalClientInfo.subsite;
  const proposalClientId = proposalClientInfo.client;
  return [].concat({
    // allow saving wihout error to telling user to send request for approval
    keyword: 'can_publish_content',
    subsite: proposalSubsiteId,
    ruby_client: proposalClientId,
    action: '*'
  }, {
    keyword: 'hydrate_sitemap',
    ruby_client: proposalClientId,
    action: '*'
  }
  //# find the proposal template
  //# find all propopsal page templates
  //# find all templates that can be added to proposal
  , [].concat.apply(templates.filter(template => {
    return template.key == 'proposal' || template.templateType == 'proposal_page_template' || template.ruby_subsite_fk == null || _.isArray(template.ruby_subsite_fk) && template.ruby_subsite_fk.length === 0 || _.isArray(template.ruby_subsite_fk) && _.includes(template.ruby_subsite_fk, PROPOSAL_SUBSITE_ID);
  }).map(template => {
    return {
      model: 'content',
      template: template.id.toString(),
      ruby_client: proposalClientId,
      subsite: proposalSubsiteId,
      action: '*'
    };
  })));
}
function getGenerateProposalPermValue_fromTemplates(templates, selfModule) {
  const proposalRoute = '/app/proposal';
  const proposalClientInfo = selfModule.getRubyClientInfo_forRoute(proposalRoute);
  const proposalSubsiteId = proposalClientInfo.subsite;
  const proposalClientId = proposalClientInfo.client;
  return [].concat({
    // allow saving wihout error to telling user to send request for approval
    keyword: 'can_publish_content',
    subsite: proposalSubsiteId,
    ruby_client: proposalClientId,
    action: '*'
  }, {
    keyword: 'hydrate_sitemap',
    ruby_client: proposalClientId,
    action: '*'
  }, {
    // allow saving the page after generating a word doc,
    // which sets the word_download_url field
    keyword: 'modify_word_download_url',
    ruby_client: proposalClientId,
    action: 'edit'
  }, {
    // allow saving wihout error to telling user to send request for approval
    keyword: 'can_generate_proposal_doc',
    subsite: proposalSubsiteId,
    ruby_client: proposalClientId,
    action: '*'
  }
  //# find the proposal template
  //# find all propopsal page templates
  //# find all templates that can be added to proposal
  , [].concat.apply(templates.filter(template => {
    return template.key == 'proposal';
  }).map(template => {
    return {
      model: 'content',
      template: template.id.toString(),
      ruby_client: proposalClientId,
      subsite: proposalSubsiteId,
      action: 'get'
    };
  })));
}
function getPerms_fromTemplates_forType(templates, type, selfModule) {
  const type_prefix = 'lookup';
  const {
    subsiteLabel,
    subsite,
    client
  } = selfModule.getRubyClientInfo_forRoute(`/app/${type_prefix}`);
  const permissionKeys = [];
  const permissionsByKey = templates.filter(template => template.templateType === type).map(template => {
    const templateId = template.id.toString();
    const templateKeyword = template.key;
    const permId = `lookup_${templateKeyword}`;
    const childrenPermSpecs = [].concat({
      action: PERMISSION_ACTIONS.GET,
      id: `${type_prefix}_${templateKeyword}__${PERMISSION_ACTIONS.GET}__MINIMAL`,
      label: 'View - Minimal',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.GET,
        property: ['getSelectOptions', 'getSuggestedSelectOptions']
      })
    }, {
      action: PERMISSION_ACTIONS.GET,
      id: `${type_prefix}_${templateKeyword}__${PERMISSION_ACTIONS.GET}`,
      label: 'View - Full',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.GET,
        property: PERMISSION_ACTIONS.ANY
      })
    }, {
      action: PERMISSION_ACTIONS.EDIT,
      id: `${type_prefix}_${templateKeyword}__${PERMISSION_ACTIONS.EDIT}`,
      label: 'Update',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.EDIT
      })
    }, {
      action: PERMISSION_ACTIONS.ADD,
      id: `${type_prefix}_${templateKeyword}__${PERMISSION_ACTIONS.ADD}`,
      label: 'Create',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.ADD
      })
    }, {
      action: PERMISSION_ACTIONS.DELETE,
      id: `${type_prefix}_${templateKeyword}__${PERMISSION_ACTIONS.DELETE}`,
      label: 'Delete',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.DELETE
      })
    });

    //# NOTE: do not include the nested permission items as keys
    //# or they will get rendered under "lookups"
    permissionKeys.push(`lookup_${templateKeyword}`);
    return {
      label: template.name,
      isLookup: true,
      id: permId,
      templateKeyword,
      templateId
      /*
      , value: {
          model: 'content'
          , template: template.id.toString()
          , ruby_client: 3
          , subsite: 1
          , action: '*'
      }
      */,
      children: childrenPermSpecs
    };
  }).reduce((result, templateTogglePermObject, idx) => {
    const permObject_flat = normalizedPermissionObject(templateTogglePermObject);
    return _objectSpread(_objectSpread({}, result), permObject_flat);
  }, {});
  return {
    permissionKeys,
    permissionsByKey
  };
}
function getSitemapPerms_fromTemplates(templates, selfModule) {
  const {
    subsiteLabel,
    subsite,
    client
  } = selfModule.getRubyClientInfo_forRoute('/app/content');
  const aclsSitemapSection = {
    keyword: 'content_sitemap_section',
    ruby_client: 3,
    action: '*'
  };
  const aclsForTemplates = templates.filter(template => template.templateType === 'sitemap_template').map(template => ({
    model: 'content',
    template: template.id.toString(),
    ruby_client: client,
    subsite: subsite,
    action: '*'
  }));
  const permId = 'sitemap';
  const childrenPermSpecs = [].concat({
    action: PERMISSION_ACTIONS.GET,
    id: `${permId}__${PERMISSION_ACTIONS.GET}__MINIMAL`,
    label: 'View - Minimal',
    inheritPageSpecificityFromId: permId,
    value: aclsForTemplates.map(aclForTemplate => _objectSpread(_objectSpread({}, aclForTemplate), {}, {
      action: PERMISSION_ACTIONS.GET,
      property: ['getSelectOptions', 'getSuggestedSelectOptions']
    }))
  }, {
    action: PERMISSION_ACTIONS.GET,
    id: `${permId}__${PERMISSION_ACTIONS.GET}`,
    label: 'View - Full',
    inheritPageSpecificityFromId: permId,
    value: aclsForTemplates.map(aclForTemplate => _objectSpread(_objectSpread({}, aclForTemplate), {}, {
      action: PERMISSION_ACTIONS.GET,
      property: PERMISSION_ACTIONS.ANY
    }))
  }, {
    action: PERMISSION_ACTIONS.EDIT,
    id: `${permId}__${PERMISSION_ACTIONS.EDIT}`,
    label: 'Update',
    inheritPageSpecificityFromId: permId,
    value: aclsForTemplates.map(aclForTemplate => _objectSpread(_objectSpread({}, aclForTemplate), {}, {
      action: PERMISSION_ACTIONS.EDIT
    }))
  }, {
    action: PERMISSION_ACTIONS.ADD,
    id: `${permId}__${PERMISSION_ACTIONS.ADD}`,
    label: 'Create',
    inheritPageSpecificityFromId: permId,
    value: aclsForTemplates.map(aclForTemplate => _objectSpread(_objectSpread({}, aclForTemplate), {}, {
      action: PERMISSION_ACTIONS.ADD
    }))
  }, {
    action: PERMISSION_ACTIONS.DELETE,
    id: `${permId}__${PERMISSION_ACTIONS.DELETE}`,
    label: 'Delete',
    inheritPageSpecificityFromId: permId,
    value: aclsForTemplates.map(aclForTemplate => _objectSpread(_objectSpread({}, aclForTemplate), {}, {
      action: PERMISSION_ACTIONS.DELETE
    }))
  });
  const permissionsByKey = normalizedPermissionObject({
    label: 'Sitemap Pages',
    id: permId,
    isSitemap: true,
    children: childrenPermSpecs,
    value: [aclsSitemapSection]
  });
  return {
    permissionsByKey
  };
}
function getSDPerms_fromTemplates(templates, selfModule) {
  const {
    subsiteLabel,
    subsite,
    client
  } = selfModule.getRubyClientInfo_forRoute('/app/content');
  const structuredDataPermissionKeys = [];
  const structuredDataPermissionsByKey = templates.filter(template => template.templateType === 'structured_data_template' && !_.includes(['global_data', 'rubyapps_settings'], template.key)).map(template => {
    const templateId = template.id.toString();
    const templateKeyword = template.key;
    const permId = `sd_${templateKeyword}`;
    const childrenPermSpecs = [].concat({
      action: PERMISSION_ACTIONS.GET,
      id: `sd_${templateKeyword}__${PERMISSION_ACTIONS.GET}__MINIMAL`,
      label: 'View - Minimal',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.GET,
        property: ['getSelectOptions', 'getSuggestedSelectOptions']
      })
    }, {
      action: PERMISSION_ACTIONS.GET,
      id: `sd_${templateKeyword}__${PERMISSION_ACTIONS.GET}`,
      label: 'View - Full',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.GET,
        property: PERMISSION_ACTIONS.ANY
      })
    }, {
      action: PERMISSION_ACTIONS.EDIT,
      id: `sd_${templateKeyword}__${PERMISSION_ACTIONS.EDIT}`,
      label: 'Update',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.EDIT
      })
    }, {
      action: PERMISSION_ACTIONS.ADD,
      id: `sd_${templateKeyword}__${PERMISSION_ACTIONS.ADD}`,
      label: 'Create',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.ADD
      })
    }, {
      action: PERMISSION_ACTIONS.DELETE,
      id: `sd_${templateKeyword}__${PERMISSION_ACTIONS.DELETE}`,
      label: 'Delete',
      inheritPageSpecificityFromId: permId,
      templateId,
      value: [].concat({
        model: 'content',
        template: templateId,
        ruby_client: client,
        subsite,
        action: PERMISSION_ACTIONS.DELETE
      })
    });

    //# NOTE: do not include the nested permission items as keys
    //# or they will get rendered under "structured data"
    structuredDataPermissionKeys.push(`sd_${templateKeyword}`);
    return {
      label: template.name,
      isStructuredData: true,
      id: permId,
      templateKeyword,
      templateId
      /*
      , value: {
          model: 'content'
          , template: template.id.toString()
          , ruby_client: 3
          , subsite: 1
          , action: '*'
      }
      */,
      children: childrenPermSpecs
    };
  }).reduce((result, templateTogglePermObject, idx) => {
    const permObject_flat = _.mapKeys(normalizedPermissionObject(templateTogglePermObject), (value, key) => `${key}`);
    return _objectSpread(_objectSpread({}, result), permObject_flat);
  }, {});
  return {
    structuredDataPermissionKeys,
    structuredDataPermissionsByKey
  };
}

/*
    Given: permObject = {label: 'Blog', isStructuredData: true, id: 'blog', templateKeyword: Array(1), templateId: Array(1), children: ...}
    returns: {
        blog: {...}
        , blog__get: {...}
        , blog__add: {...}
    }
 */
function normalizedPermissionObject(permObject) {
  function generateId(entity, id) {
    const newId = _.get(entity, 'id') || (nextId++).toString();
    return newId;
  }
  const permissionSchema = new Schema('permissions', {
    idAttribute: generateId
  });
  permissionSchema.define({
    children: arrayOf(permissionSchema)
  });

  //# TODO: confirm that it's been  flattened
  const permissionItemsByKey = _.get(normalize(permObject, permissionSchema), 'entities.permissions');
  return permissionItemsByKey;
}
module.exports = getAutoPopulatedPerms_fromTemplates_andPermItems;