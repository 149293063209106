// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RubyComponentFieldArrayMap__RaisedButton___iOLDp {
    max-width: 100px;
}
.RubyComponentFieldArrayMap__RaisedButton_primary___oo6QQ {
}

.RubyComponentFieldArrayMap__wrapper___Nqcin {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-top: 16px;
}

.RubyComponentFieldArrayMap__childrenContainer___hbTSO {
    display: flex;
    align-items: center;
}
.RubyComponentFieldArrayMap__childrenWrapper___Y_UKt {
    margin-right: 15px;
    width: 100%;
}
.RubyComponentFieldArrayMap__childrenWrapper___Y_UKt:last-child {
    margin-right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-array-map/src/client/reactComponents/RubyComponentFieldArrayMap.cssModule"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;AACA;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".RaisedButton {\n    max-width: 100px;\n}\n.RaisedButton_primary {\n}\n\n.wrapper {\n    margin-top: 16px;\n    margin-bottom: 16px;\n    padding-top: 16px;\n}\n\n.childrenContainer {\n    display: flex;\n    align-items: center;\n}\n.childrenWrapper {\n    margin-right: 15px;\n    width: 100%;\n}\n.childrenWrapper:last-child {\n    margin-right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RaisedButton": `RubyComponentFieldArrayMap__RaisedButton___iOLDp`,
	"RaisedButton_primary": `RubyComponentFieldArrayMap__RaisedButton_primary___oo6QQ`,
	"wrapper": `RubyComponentFieldArrayMap__wrapper___Nqcin`,
	"childrenContainer": `RubyComponentFieldArrayMap__childrenContainer___hbTSO`,
	"childrenWrapper": `RubyComponentFieldArrayMap__childrenWrapper___Y_UKt`
};
export default ___CSS_LOADER_EXPORT___;
