// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading__refreshIndicator___B_9pT {
    display: inline-block;
    position: relative;
}
.loading__refreshDivWrapper___aBVBS {
    position: relative;
    width: 100%;
}
.loading__refreshDivContent___ht4j2 {
    position: absolute;
    width: 100%;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-render/src/loading.cssModule"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".refreshIndicator {\n    display: inline-block;\n    position: relative;\n}\n.refreshDivWrapper {\n    position: relative;\n    width: 100%;\n}\n.refreshDivContent {\n    position: absolute;\n    width: 100%;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshIndicator": `loading__refreshIndicator___B_9pT`,
	"refreshDivWrapper": `loading__refreshDivWrapper___aBVBS`,
	"refreshDivContent": `loading__refreshDivContent___ht4j2`
};
export default ___CSS_LOADER_EXPORT___;
