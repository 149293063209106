"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Textarea = _interopRequireDefault(require("./Textarea"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();

  //# figure out the toHTML ahead of time
  const hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const suggestedValue = selfModule.getReferencedFormValue(state);
    //const suggestedValue = _.pick(selfModule.getProps(true),['suggestedValue']);

    const explicitValue = _.get(selfState.fields[selfModule.props.key], 'value');
    const valueAvailable = explicitValue != null && explicitValue != '';
    //# NOTE:, _formValueFromLocalState for this component checks if value is null and defaults to the referenced formValue
    //# the _getValuesFromFieldsObject > predicateFormatter returns null for ''

    const shouldAutofillContent = !!(selfModule.props.autopopulate && suggestedValue && !valueAvailable);

    //# Hacky, but used to clear the parent errors for now
    if (!valueAvailable) {
      //# if we don't have a local value
      //# we should refresh the parent errors in case we need to set or clear them
      selfModule.refreshParentErrors();
    }
    const mappedProps = _extends({}, ownProps, mixinFieldProps, {
      id: selfModule.getID()
    }, selfModule.props.key ? _.pick(selfState.fields[selfModule.props.key], ['value', 'userModifiedTimestamp']) : {}, formattedError, hydratedToHTML ? {
      toHTML: hydratedToHTML
    } : {}, shouldAutofillContent ? {
      value: suggestedValue
    } : {
      suggestedValue
    }, {
      isAutoPopulated: shouldAutofillContent
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Textarea.default);
}