// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__ReactSelectize___xgnZh {
    width: 100%;
}

.index__SimpleSelect___vtMM7 {
}
.index__SimpleSelect___vtMM7 .simple-value {
    text-overflow: inherit !important;
    overflow: inherit !important;

    display: flex;
    align-items: center;
}
.index__SimpleSelect___vtMM7 .simple-value > span {
    text-overflow: ellipsis;
    overflow:hidden;
}
.index__SimpleSelect___vtMM7 .simple-value > span > span {
    display: inline!important;
}

.index__MultiSelect___vLo8q {
}

.option-wrapper.highlight {
    background-color: rgba(0, 0, 0, 0.1);
}

.index__Menu__List___ISOpk .ReactVirtualized__Grid__innerScrollContainer {
    max-width: 100% !important;
}

.index__MenuItem__SecondaryText___G4r3P {
    font-size: 10px;
    padding-left: 10px;
    float: right;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-react-components/src/client/input/Selectize/index.cssModule"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;AAEA;AACA;IACI,iCAAiC;IACjC,4BAA4B;;IAE5B,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;;AAEA;AAEA;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".ReactSelectize {\n    width: 100%;\n}\n\n.SimpleSelect {\n    composes: ReactSelectize\n}\n.SimpleSelect :global(.simple-value) {\n    text-overflow: inherit !important;\n    overflow: inherit !important;\n\n    display: flex;\n    align-items: center;\n}\n.SimpleSelect :global(.simple-value > span) {\n    text-overflow: ellipsis;\n    overflow:hidden;\n}\n.SimpleSelect :global(.simple-value > span > span) {\n    display: inline!important;\n}\n\n.MultiSelect {\n    composes: ReactSelectize\n}\n\n:global(.option-wrapper.highlight) {\n    background-color: rgba(0, 0, 0, 0.1);\n}\n\n.Menu__List :global(.ReactVirtualized__Grid__innerScrollContainer) {\n    max-width: 100% !important;\n}\n\n.MenuItem__SecondaryText {\n    font-size: 10px;\n    padding-left: 10px;\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReactSelectize": `index__ReactSelectize___xgnZh`,
	"SimpleSelect": `index__SimpleSelect___vtMM7 index__ReactSelectize___xgnZh`,
	"MultiSelect": `index__MultiSelect___vLo8q index__ReactSelectize___xgnZh`,
	"Menu__List": `index__Menu__List___ISOpk`,
	"MenuItem__SecondaryText": `index__MenuItem__SecondaryText___G4r3P`
};
export default ___CSS_LOADER_EXPORT___;
