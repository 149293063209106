// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea__textarea___BAbt0 {
    width: 100%;
    background: #fff;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 8px;
    box-sizing: border-box;
    resize: vertical;
}

.Textarea__textarea___BAbt0::placeholder {
    color: rgba(0, 0, 0, 0.26);
}

.Textarea__toolbar___i1gkH {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
}

.Textarea__toolbarButton___P6qvU {
    transform: scale(0.8);
}
.Textarea__toolbarButtonWrapper___eRy1o {
    display: inline-block;
    margin-right: 10px;
}
.Textarea__toolbarButtonWrapper___eRy1o:last-child {
    margin-right: 0;
}

.Textarea__toolbarButton___P6qvU button .fa {
    font-size: 22px!important;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-textarea/src/client/reactComponents/Textarea.cssModule"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,iCAAiC;IACjC,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".textarea {\n    width: 100%;\n    background: #fff;\n    border: 1px solid rgba(0,0,0,0.2);\n    padding: 8px;\n    box-sizing: border-box;\n    resize: vertical;\n}\n\n.textarea::placeholder {\n    color: rgba(0, 0, 0, 0.26);\n}\n\n.toolbar {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 4px;\n}\n\n.toolbarButton {\n    transform: scale(0.8);\n}\n.toolbarButtonWrapper {\n    display: inline-block;\n    margin-right: 10px;\n}\n.toolbarButtonWrapper:last-child {\n    margin-right: 0;\n}\n\n.toolbarButton button :global(.fa) {\n    font-size: 22px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `Textarea__textarea___BAbt0`,
	"toolbar": `Textarea__toolbar___i1gkH`,
	"toolbarButton": `Textarea__toolbarButton___P6qvU`,
	"toolbarButtonWrapper": `Textarea__toolbarButtonWrapper___eRy1o`
};
export default ___CSS_LOADER_EXPORT___;
