"use strict";

//# https://github.com/trentm/node-bunyan
//# https://www.loggly.com/blog/a-benchmark-of-five-node-js-logging-libraries/
//# https://github.com/trentm/node-bunyan/issues/401
//#     closure before checking if we should log something out will possibly hamper performance
//#     consider replacing the log methods with empty functions based on logging level
var nodePath = require('path');
//var parsePath = require('path-parse');
//# polyfill nodePath.parse
//nodePath.parse = parsePath; //# don't need this anymore for webpack 5 since we use path-browserify

var bunyan = require('bunyan');
var bunyanFormat = require('bunyan-format');
var bunyanRotatingFileStreamGenerator;
try {
  var BunyanRotatingFileStream = require('bunyan-rotating-file-stream');
  bunyanRotatingFileStreamGenerator = function (options) {
    return new BunyanRotatingFileStream(options);
  };
} catch (err) {
  //# rotating file stream shouldn't be used on the frontend anyways so lets set it to bunyanFormat
  bunyanRotatingFileStreamGenerator = bunyanFormat;
}
var _ = require('lodash');
const stringify = require('json-stringify-safe');
const streamCreatorsByKey = {
  'bunyan-format': bunyanFormat,
  'bunyan-rotating-file-stream': bunyanRotatingFileStreamGenerator
};

//# TODO: https://www.stacktracejs.com/
//#     Add this to the dev trace output

function newDefaultBunyanOptions() {
  var NODE_ENV = "production";
  var isDebugEnv = NODE_ENV !== 'production' ? true : false;

  //== Default Options  ===================================================

  var defaultBunyanOptions = {
    name: 'RubyLogger',
    src: isDebugEnv ? true : false,
    level: isDebugEnv ? 'trace' : 'info'
    //# we default the level to trace if node_env is debug
    //# but the developer can always override it with configs
    ,
    streams: []
    /*
    , streams: [ {
        type: 'rotating-file',
        path:  LOG_FILEPATH,
        period: LOG_ROTATION_PERIOD,
        count: LOG_ROTATION_COUNT,
        level: bunyan.TRACE, //# acccept all levels of logging.
            //# My understanding is that you set the level above and
            //# only the streams that can respond to it will respond
        name: 'FileLogger'
    }]
    */
  };

  return defaultBunyanOptions;
}

//== SETUP =========================================================================

function redirectConsole_toLogger(logger) {
  if (typeof console !== 'undefined') {
    //# console is available so we tap into it
    for (var key in console) {
      var oldConsoleFn = console[key];
      if (typeof oldConsoleFn === 'function') {
        //# patch console
        if (logger[key]) {
          console[key] = _newBoundConsoleFunction(console, logger, logger[key], oldConsoleFn);
        }
      }
    }

    //# map console.log => debug
    //# Fails when trying to use tape. My guess is that console.log() has to be used to output to stdout
    //# 20160411: doesn't look like we need to monkey patch the console.log.. we get it in the bunyan stream already
    //console.log = logger.debug.bind(logger);
  }

  return;
  function _newBoundConsoleFunction(console, logger, loggerFn, consoleFn) {
    return function () {
      loggerFn.apply(logger, arguments);
      consoleFn.apply(console, arguments);
    };
  }
}

//# hydrate the config options since the options might have been
//# given to us from a JSON file
function _hydrateLoggerOptions(options) {
  var streams = options.streams;
  var hydratedStreams = _.map(streams, function (streamConfig) {
    var hydratedStreamConfig = _.assign({}, streamConfig);
    if (_.isNil(streamConfig.stream)) {
      return hydratedStreamConfig;
    }
    if (streamConfig.stream == 'stdout') {
      hydratedStreamConfig.stream = process.stdout;
    } else if (streamConfig.stream == 'stderr') {
      hydratedStreamConfig.stream = process.stderr;
    } else if (!_.isNil(streamConfig.stream.length)) {
      hydratedStreamConfig.stream = streamCreatorsByKey[streamConfig.stream[0]].apply(null, streamConfig.stream.slice(1));
    }
    return hydratedStreamConfig;
  });
  var hydratedOptions = _.assign({}, options, {
    streams: hydratedStreams
  });
  return hydratedOptions;
}
function _dynamicallyRequireModules(modules) {
  _.each(modules, function (packageName) {
    if (_.isNil(streamCreatorsByKey[packageName])) {
      streamCreatorsByKey[packageName] = require(packageName);
    }
  });
}
function _getLoggerOptions_fromConfig(configOptions) {
  var streams = _.reduce(configOptions.streamsByName ? configOptions.streamsByName : [], function (collector, streamConfig) {
    collector.push(streamConfig);
    return collector;
  }, []);
  var loggerOptions = _.omit(configOptions, ['require', 'namespaces', 'streamsByName']);
  loggerOptions.streams = streams;

  //# remove null values
  var reducer = function (collector, value, key) {
    var reducedValue = value;
    if (!_.isNil(value)) {
      if (_.isArray(value)) {
        reducedValue = _.reduce(value, reducer, []);
      } else if (_.isPlainObject(value)) {
        reducedValue = _.reduce(value, reducer, {});
      }
    }
    if (!_.isNil(reducedValue)) {
      collector[key] = reducedValue;
    }
    return collector;
  };
  return _.reduce(loggerOptions, reducer, {});
}
var bunyanManager = {
  bunyan: bunyan,
  loggerConfig: {},
  _logger: undefined,
  _childLoggersByKey: {},
  formatModulePath: function (path) {
    var extname = nodePath.extname(path);
    if (extname.length) {
      path = path.slice(0, -1 * extname.length);
    }
    var pathArr = path.split('/');
    var firstIndexOf_localModules = pathArr.indexOf('local_modules');
    var reducedPathArr = pathArr.slice(firstIndexOf_localModules + 1);
    return nodePath.join.apply(null, reducedPathArr);
  }
  //# the 'options' is the rest of the bunyan options
  ,
  createLogger: function (options) {
    var callerOptions;
    if (this._logger) {
      console.warn('You\'re creating multiple instances of the bunyan logger. This is probably a bad idea');
    }
    if (typeof options === 'string') {
      callerOptions = {
        name: options
      };
    } else {
      callerOptions = options;
    }
    var configOptions = _.assign({}, newDefaultBunyanOptions(), callerOptions);
    _dynamicallyRequireModules(configOptions.require ? configOptions.require : []);
    this.loggerConfig = _.assign({}, configOptions); //# to be used by getLogger()

    var loggerOptions = _getLoggerOptions_fromConfig(configOptions);
    loggerOptions = _hydrateLoggerOptions(loggerOptions);
    if (loggerOptions.streams.length === 0) {
      delete loggerOptions.streams;
    }
    var logger = bunyan.createLogger(loggerOptions);
    this._logger = augmentedLogger(logger);
    return this._logger;
  },
  //# TODO we should update the method signature to accept options which will create a child logger
  //https://github.com/trentm/node-bunyan#logchild
  //# returns a log child instead of the parent/root logger
  getLogger: function getLogger(options) {
    //# TODO: temporariy fix for browser error
    if (console.debug === undefined) {
      console.debug = console.log;
    }
    var childConfigOptions;
    var rootLogger = this._logger;
    if (options === undefined) {
      return rootLogger;
    }
    if (typeof options == 'string') {
      childConfigOptions = {
        TAG: options
      };
    } else {
      childConfigOptions = options;
    }

    //# check if we have additional options to include
    var additionalConfig = this.loggerConfig.namespaces ? this.loggerConfig.namespaces[childConfigOptions.TAG] : {};
    if (additionalConfig) {
      childConfigOptions = _.assign(childConfigOptions, additionalConfig);
    }
    _dynamicallyRequireModules(childConfigOptions.require ? childConfigOptions.require : []);
    var childLoggerOptions = _getLoggerOptions_fromConfig(childConfigOptions);
    childLoggerOptions = _hydrateLoggerOptions(childLoggerOptions);
    const childLogger = augmentedLogger(rootLogger.child(childLoggerOptions));
    this._childLoggersByKey[childConfigOptions.TAG] = childLogger;
    return childLogger;
    //# perhaps return an instance of the bunyan logger that will not log out anything
    //# if the config wants to ignore / filter out a module
    //
    //# config.ignoreModules = [ ...]
  },

  redirectConsole_toLogger: redirectConsole_toLogger
};
function augmentedLogger(logger) {
  //# proxy other options to safe-stringfy
  function proxyLoggerMethod(logger, methodName) {
    logger[methodName] = new Proxy(logger[methodName], {
      apply(target, thisArg, argumentsList) {
        const updatedArgumentsList = [...argumentsList].map(n => {
          if (_.isObject(n)) {
            try {
              return stringify(n, null, 2);
            } catch (err) {
              return ['Error stringifying logger arg with error:', err];
            }
          }
          return n;
        });
        return Reflect.apply(target, thisArg, updatedArgumentsList);
      }
    });
  }
  if (!process.browser) {
    //# only proxy to stringify objects in server
    //# in browser, we can view the full object
    //# NOTE: it's also common in the browser that we log out class instances
    //# which some fail to stringify
    //# plus it's costly to attempt to stringify
    ["fatal", "error", "warn", "info", "debug", "trace"].forEach(methodName => {
      //proxyLoggerMethod(logger, methodName);
      //# NOTE: we are using util.format to format 
      //# which has a limitation with how large of an object it logs out
      //# but we do not want to use this proxy method because we generate the msg
      //# before we determnine if it should be logged out
      //# we might need to register a formatter and use a formatted string instead
    });
  }

  //# time augmentation
  let startTimeMap = {}; //# map by time argument string to Date object
  logger.time = function (label) {
    startTimeMap[label] = new Date();
  };
  logger.timeEnd = function (label) {
    let loggerMethod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : logger.debug;
    const startTime = startTimeMap[label];
    delete startTimeMap[label];
    const endTime = new Date();
    loggerMethod.call(logger, label, `Time: ${(endTime - startTime) / 1000} secs`);
  };
  return logger;
}

//# NOTE: it's ok to automatically initialize the logger because any overriding should be specified in the config file

//# TODO: console redirection initialization should be dictated by a flag in the config.js file
/*
    if (redirectConsole != undefined && !redirectConsole) {
        redirectConsole_toLogger(logger);
    }
*/

module.exports = bunyanManager;