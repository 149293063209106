// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customComponent_styles__RubyActions___PICHu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.customComponent_styles__InfoField___FS4zA ul, .customComponent_styles__InfoField___FS4zA ol {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.customComponent_styles__InfoField___FS4zA ol {
    list-style-type: decimal;
}

.customComponent_styles__InfoField___FS4zA .highlight {
    background-color: #fff700;
}

.customComponent_styles__DateFormatterLabel___JlaBP {
    width: 84px;
    margin-right: 4px;
}

.customComponent_styles__LeftIconWrapper___N9Snp {
    width: 20px;
}

.customComponent_styles__LeftIconWrapper__toggleWidth___EXos7 {
    width: 46px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/griddle/customComponents/customComponent_styles.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;;AAGA;IACI,cAAc;IACd,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;IACrB,wBAAwB;IACxB,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".RubyActions {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n\n.InfoField ul, .InfoField ol {\n    display: block;\n    list-style-type: disc;\n    margin-block-start: 1em;\n    margin-block-end: 1em;\n    margin-inline-start: 0px;\n    margin-inline-end: 0px;\n    padding-inline-start: 40px;\n}\n\n.InfoField ol {\n    list-style-type: decimal;\n}\n\n.InfoField :global(.highlight) {\n    background-color: #fff700;\n}\n\n.DateFormatterLabel {\n    width: 84px;\n    margin-right: 4px;\n}\n\n.LeftIconWrapper {\n    width: 20px;\n}\n\n.LeftIconWrapper__toggleWidth {\n    width: 46px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RubyActions": `customComponent_styles__RubyActions___PICHu`,
	"InfoField": `customComponent_styles__InfoField___FS4zA`,
	"DateFormatterLabel": `customComponent_styles__DateFormatterLabel___JlaBP`,
	"LeftIconWrapper": `customComponent_styles__LeftIconWrapper___N9Snp`,
	"LeftIconWrapper__toggleWidth": `customComponent_styles__LeftIconWrapper__toggleWidth___EXos7`
};
export default ___CSS_LOADER_EXPORT___;
