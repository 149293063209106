"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');

//# NOTES: convert the dataPath from jsonPointers notation to dot notation. e.g., 
//# from: /super_template_modules/0/repeater_module_super_template_reusable_modules/reusable_modules
//# to: .super_template_modules[0].repeater_module_super_template_reusable_modules.reusable_modules
function convertDataPath_fromJsonPointers_toDotNotation(dataPath) {
  if (dataPath.charAt(0) !== '/') {
    //# no slashes assume it's already a dot-notation

    return dataPath;
  }
  const pathArr = dataPath.split("/").filter(n => n != "");
  //# omit the leading empty value if the path is "/path/to/key" => ["", "path", "to", "key"]
  return _.reduce(pathArr, (acc, curr) => {
    if (curr.match(/^\d+$/)) {
      acc = acc + `[${curr}]`;
    } else {
      acc = acc + "." + (curr == undefined ? '' : curr);
    }
    return acc;
  }, "");
}
function convertJsonSchemaError_toErrorPath(jsonSchemaError) {
  const {
    schemaPath,
    dataPath
  } = jsonSchemaError;

  // Variations:
  //  SP: #/required                                       DP: .name
  //  SP: #/properties/name/minLength                      DP: .name
  //  SP: #/properties/date/required                       DP: .timeZone
  //  SP: #/properties/date/properties/timeZone/minLength  DP: .date.timeZone
  //  SP: #/required                                       DP: /name
  //  SP: #/properties/name/minLength                      DP: /name
  //  SP: #/properties/date/required                       DP: /timeZone
  //  SP: #/properties/date/properties/timeZone/minLength  DP: /date/timeZone

  // So, we only need to handle the special case of required error for nested field
  const nestedRequiredMatch = /^#\/properties\/(.+?)\/required$/.exec(schemaPath);
  if (nestedRequiredMatch) {
    return [nestedRequiredMatch[1], dataPath].join('');
  } else {
    const convertedDataPath = convertDataPath_fromJsonPointers_toDotNotation(dataPath).substr(1);
    //# strip out prefix '.'

    //# if the last node in the datapath targets an array directly.
    //# we need to nest it in an object
    if (convertedDataPath.charAt(convertedDataPath.length - 1) == ']') {
      return convertedDataPath + '._message';
    }
    return convertedDataPath;
  }
}

//# NOTE: should only be used by the frontend
function convertJsonSchemaErrors_toLoopbackErrors(errors) {
  //# see if we can convert errors
  return errors.reduce((lbErrors, jsonSchemaError) => {
    const errorPath = convertJsonSchemaError_toErrorPath(jsonSchemaError);
    const existingErrorMessage = _.get(lbErrors, errorPath);
    const error = _.set({}, errorPath, existingErrorMessage ? `${existingErrorMessage} ${jsonSchemaError.message}` : jsonSchemaError.message);
    return _.merge({}, lbErrors, error); //# merge instead of assign because the error might be an array of objects
  }, {});
}
const Errors = {
  formattedErrorObject_usingErrorResponseBody: function (errorResponseBody) {
    let keysToMappedKeysHash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var errorObj = {};
    if (errorResponseBody.error.code === 'AUTHORIZATION_REQUIRED' && errorResponseBody.error.statusCode === 401) {
      errorObj.errors = errorResponseBody.error;
      errorObj.field_perms_errors = errorResponseBody.error.details;
    } else if (errorResponseBody.error.name === 'ValidationError' && errorResponseBody.error.statusCode === 422) {
      //errorObject.error = errorResponseBody.error;
      var form_errors = _.mapValues(errorResponseBody.error.details.messages, err => _.last(err) || _.get(err, [0]));
      for (var key in keysToMappedKeysHash) {
        let errorForKey = form_errors[key];
        let mappedKey = keysToMappedKeysHash[key];
        if (errorForKey) {
          form_errors[mappedKey] = errorForKey;
          delete form_errors[key];
        }
      }
      errorObj.form_errors = form_errors;

      // We need the context to tell if this is an approval error
      // Or an error with the underlying data
      errorObj.context = errorResponseBody.error.details.context;
    } else {
      errorObj.errors = errorResponseBody.error;
    }
    return errorObj;
  },
  createTemplateKeywordError: function (e) {
    var err = new Error("Invalid Data");
    err.statusCode = 422;
    err.details = [{
      keyword: 'validTemplateKeyword',
      dataPath: '.template_keyword',
      schemaPath: '#/properties/template_keyword/validTemplateKeyword',
      params: {},
      message: 'should be valid template'
    }];
    return err;
  },
  createServerError: function (e) {
    console.log('Error: ', e);
    var err = new Error("Internal Server Error");
    err.statusCode = 500;
    err.details = [{
      error: e
    }];
    return err;
  },
  uniqueUrlIdentifierValidationError: function () {
    const code = 'INVALID_URL_IDENTIFIER';
    const message = 'URL Identifiers must be unique.';
    return customContentValidationError([{
      prop: 'url_identifier',
      code,
      message
    }]);
  },
  customContentValidationError,
  invalidContent_templateKeywordError: function (expectedTemplateKeyword) {
    const err = new Error('Content does not have expected template keyword');
    err.statusCode = 422;
    err.details = [{
      keyword: 'validTemplateKeyword',
      dataPath: '.template_keyword',
      schemaPath: '#/properties/template_keyword/validTemplateKeyword',
      params: {},
      message: `Unexpected template. Should be: ${expectedTemplateKeyword}`
    }];
    return err;
  },
  convertJsonSchemaError_toErrorPath,
  convertJsonSchemaErrors_toLoopbackErrors,
  convertDataPath_fromJsonPointers_toDotNotation,
  dataPath_strippedLeadingDelimiter: function dataPath_strippedLeadingDelimiter(dataPath) {
    return dataPath.replace(/^(\/|\.)/, '');
  },
  convertDataPath_toArray: function convertDataPath_toArray(dataPath) {
    //# should handle both arrays and dot notation
    const isJsonPointer = dataPath.charAt(0) == '/';
    if (isJsonPointer) {
      return dataPath.split('/');
    } else {
      return _.toPath(dataPath);
    }
  },
  dataPath_containsKey(dataPath, key) {
    const isJsonPointer = dataPath.charAt(0) == '/';
    if (isJsonPointer) {
      return dataPath.indexOf(`/${key}`) !== -1;
    } else {
      return dataPath.indexOf(`.${key}`) !== -1;
    }
  },
  dataPath_concatKey(dataPath, key) {
    const isJsonPointer = dataPath.charAt(0) == '/';
    if (isJsonPointer) {
      return `${dataPath ? dataPath : ''}/${key}`;
    } else {
      return `${dataPath ? dataPath : ''}.${key}`;
    }
  }
};
module.exports = Errors;
function customContentValidationError(errors) {
  var validationError = new Error('Content Validation Error');
  validationError.status = 422;
  validationError.statusCode = 422;
  validationError.details = {
    context: 'Content',
    codes: errors.reduce((codesMap, propError) => {
      const {
        prop,
        code
      } = propError;
      return _extends(codesMap, {
        [prop]: [code]
      });
    }, {}),
    messages: errors.reduce((messagesMap, propError) => {
      const {
        prop,
        message
      } = propError;
      return _extends(messagesMap, {
        [prop]: [message]
      });
    }, {})
  };
  return validationError;
}