// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-heading-cell__debugRed___CwrY0 {
    border: 1px solid red;
    min-height: 10px;
}
.table-heading-cell__debugBlue___xE7uv {
    border: 1px solid blue!important;
}

.table-heading-cell__sortableCell___KvMSz {
    cursor: pointer;
}

.table-heading-cell__sortableCell___KvMSz:hover {
    background: #DDD;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-render/src/table-heading-cell.cssModule"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".debugRed {\n    border: 1px solid red;\n    min-height: 10px;\n}\n.debugBlue {\n    border: 1px solid blue!important;\n}\n\n.sortableCell {\n    cursor: pointer;\n}\n\n.sortableCell:hover {\n    background: #DDD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debugRed": `table-heading-cell__debugRed___CwrY0`,
	"debugBlue": `table-heading-cell__debugBlue___xE7uv`,
	"sortableCell": `table-heading-cell__sortableCell___KvMSz`
};
export default ___CSS_LOADER_EXPORT___;
