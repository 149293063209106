// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-base__wrapper___KC0Ga {
    margin-top: 16px;
    margin-bottom: 16px;
}
.field-base__label___PefqE {
    z-index: 1;
    color: rgba(0, 0, 0, 0.498039);
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin-bottom: 10px;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-styles/src/client/cssModules/field-base.cssModule"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,8BAA8B;IAC9B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".wrapper {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n.label {\n    z-index: 1;\n    color: rgba(0, 0, 0, 0.498039);\n    font-size: 12px;\n    line-height: 16px;\n    display: block;\n    margin-bottom: 10px;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `field-base__wrapper___KC0Ga`,
	"label": `field-base__label___PefqE`
};
export default ___CSS_LOADER_EXPORT___;
