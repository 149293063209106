"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boolean_fromPermissionObject_andUser = void 0;
exports.doesUserMatchPermissionObject = doesUserMatchPermissionObject;
exports.idsInRubyPermissionsFromUser_forPermJSON = exports.idsInRubyPermissionsFromUser_forPerm = exports.idLimitedPermissionsFromUser_forPerm = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _rubyMemoize = require("../../../ruby-memoize");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const memoize = require('memoizee');
const propsForNormalization = ['id', 'last_modified_timestamp'];
function getNormalizedUserObject(userObject) {
  //# return userObject.toMemoizableObject();
  //# NOTE: would be better if we can use 'toMemoizableObject()' but this module is common
  //# so client-side code wouldn't have that kind of user object

  const rubyRole = typeof userObject.rubyRole == 'function' ? userObject.rubyRole().toJSON() : userObject.rubyRole;
  const normalizedUserObject = _extends({}, _lodash.default.pick(userObject, propsForNormalization), {
    rubyRole: _lodash.default.pick(rubyRole, propsForNormalization)
  });
  return normalizedUserObject;
}
;
const boolean_fromPermissionObject_andUser = memoize(raw_boolean_fromPermissionObject_andUser, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
  length: 2,
  normalizer: function (args) {
    const checkForPerm = args[0];
    const userObject = args[1];
    const normalizedUserObject = userObject ? getNormalizedUserObject(userObject) : undefined;
    const normalizedArgs = JSON.stringify([checkForPerm, normalizedUserObject]);
    return normalizedArgs;
  }
}));
exports.boolean_fromPermissionObject_andUser = boolean_fromPermissionObject_andUser;
const idsInRubyPermissionsFromUser_forPermJSON = memoize(raw_idsInRubyPermissionsFromUser_forPermJSON, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
  length: 2,
  normalizer: function (args) {
    const userObject = args[0];
    const permJSON = args[1];
    const normalizedUserObject = userObject ? getNormalizedUserObject(userObject) : undefined;
    const normalizedArgs = JSON.stringify([getNormalizedUserObject(userObject), permJSON]);
    return normalizedArgs;
  }
}));
exports.idsInRubyPermissionsFromUser_forPermJSON = idsInRubyPermissionsFromUser_forPermJSON;
const idLimitedPermissionsFromUser_forPermJSON = memoize(raw_idLimitedPermissionsFromUser_forPermJSON, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
  length: 2,
  normalizer: function (args) {
    const userObject = args[0];
    const permJSON = args[1];
    const normalizedUserObject = userObject ? getNormalizedUserObject(userObject) : undefined;
    const normalizedArgs = JSON.stringify([getNormalizedUserObject(userObject), permJSON]);
    return normalizedArgs;
  }
}));
const idsInRubyPermissionsFromUser_forPerm = function idsInRubyPermissionsFromUser_forPerm(user, perm) {
  return idsInRubyPermissionsFromUser_forPermJSON(user, JSON.stringify(perm));
};
exports.idsInRubyPermissionsFromUser_forPerm = idsInRubyPermissionsFromUser_forPerm;
const idLimitedPermissionsFromUser_forPerm = function idLimitedPermissionsFromUser_forPerm(user, perm) {
  return idLimitedPermissionsFromUser_forPermJSON(user, JSON.stringify(perm));
};
exports.idLimitedPermissionsFromUser_forPerm = idLimitedPermissionsFromUser_forPerm;
const ID_KEY = 'id';
const TEMPLATE_KEY = 'template';
const MODEL_KEY = 'model';
const ACTION_KEY = 'action';
const checkThesePermsIndividually = [ID_KEY];

//# normalize perm to include id and template if necessary
//# by default, perms that contain "model" key should have an "id" and "template" key
//# which is normalized to "*"
function normalizedHaystackPerm(perm) {
  let normalizedPerm;
  if (perm.hasOwnProperty('model')) {
    normalizedPerm = _extends({
      id: '*',
      template: '*',
      property: '*',
      action: '*',
      ruby_client: '*',
      subsite: '*'
    }, perm, {
      model: perm.model.toLowerCase() //# normalize the model name
    });
  } else if (perm.hasOwnProperty('keyword')) {
    normalizedPerm = _extends({
      property: '*',
      action: '*',
      ruby_client: '*',
      subsite: '*'
    }, perm);
  } else {
    normalizedPerm = _extends({}, perm);
  }

  //# get priority
  normalizedPerm._priority = priorityForPerm(normalizedPerm);
  return normalizedPerm;
}
function normalizedNeedlePerm(perm) {
  let normalizedPerm;
  if (perm.hasOwnProperty('model')) {
    normalizedPerm = _extends({}, perm, {
      model: perm.model?.toLowerCase() //# normalize the model name
    });
  } else {
    normalizedPerm = _extends({}, perm);
  }
  return normalizedPerm;
}
const priorityKeys = [ID_KEY, TEMPLATE_KEY, MODEL_KEY, ACTION_KEY];
const priorityEnumByKey = {
  id: 1000,
  template: 100,
  model: 10,
  action: 1
};
function priorityForPerm(perm) {
  const priority = priorityKeys.reduce((collector, key) => {
    if (perm.hasOwnProperty(key) && perm[key] != '*') {
      return collector + priorityEnumByKey[key];
    }
    return collector;
  }, 0);
  return priority;
}
function raw_boolean_fromPermissionObject_andUser(checkForPerm, userObject) {
  /* The userObject should look like:
   *     userObject = {
   *         username: "rubytest",
   *         rubyRole: {
   *             name: "Administrator",
   *             ruby_permissions: [ ... ]
   *         }
   *     }
   */

  if (!userObject || !userObject.rubyRole || !userObject.rubyRole.ruby_permissions) {
    // If it doesn't, then they DO NOT have permission
    return false;
  }
  checkForPerm = normalizedNeedlePerm(checkForPerm);
  const additional_ruby_permissions = _lodash.default.get(userObject, 'additional_ruby_permissions', []);
  const ruby_permissions = userObject.rubyRole.ruby_permissions;
  const finalRubyPermissions = ruby_permissions.concat(additional_ruby_permissions);

  /* Permissions look like:
   *      ruby_permissions: [
   *         { model: 'content', template: 12, ruby_client: 3, subsite: 1, action: 'add' },
   *         { model: 'content', template: 5,  ruby_client: 3, subsite: 1, action: 'add' }
   *         { keyword: 'sitemap',             ruby_client: 3, subsite: 1, action: 'nav' }
   *      ]
   *
   * Every permission object, must have a ruby_client and action.
   * Permissions that map to an API model, must have the model property.
   * Permissions that are not for an API model, must have the keyword property.
   * Permissions for the content model must also have a template & subsite property.
   * Any of the values can be a single value, an array or a wildcard "*"
   *
   * Actions:
   * - add lets user add entries to this model
   * - edit lets the user update entries for this model
   * - delete lets the user delete entries for this model
   * - get lets the user retrieve/list all entries from this model
   * - wildcard "*" lets the user do all of the above
   * - restricted lets the user add pages, and edit/get/delete pages based on the restricted values
   *
   * We support id specific permissions. The way it works is as follows:
   * - IF any perm has the 'id' key, then id specific permission is enabled for that permission
   * - IF the value for the 'id' is [], then NOTHING is permitted
   * - IF the value for the 'id' is [...], then only items with an id contained inside is permitted
   * - IF there's a ruby_permission object with {template: '*'}, this means it applies to all permission with a template key
   *       NOTE: specificity for permissions goes like this:
   *       [id, template, action, model]
   *       So
   *          {id: [], template: '*'} > {id:'*', template: '101'}
   *          BUT
   *          {id: [], template: '101'} > {id: [], template: '*'}
   * For keyword permissions, that might not have a specific action, it is
   * best practice to set the action to the wildcard.  When checking in the
   * to see if a user has the permission, the action shoud be "edit".
   *
   */

  const permsMatchingKeys = _lodash.default.reduce(finalRubyPermissions, (collector, perm) => {
    // Permission from user/role should be an object
    if (!_lodash.default.isObject(perm) || perm == null) {
      return collector;
    }
    const newPerm = normalizedHaystackPerm(perm);
    const permKeys = Object.keys(newPerm);
    const checkForPermKeys = Object.keys(checkForPerm);
    //# Must have the same set of base keys to match

    const diffedKeys = _lodash.default.difference(checkForPermKeys, permKeys);
    if (diffedKeys.length == 0) {
      //# checkForPerm has all the keys that perm has (ie. checkForPermKeys is a subset of permKeys)
      collector.push(newPerm);
    }
    /*
    const keysAreEquivalent = _.isEqual(_.difference(permKeys, ['_priority']), checkForPermKeys);
    if (keysAreEquivalent) {
        collector.push(newPerm);
    }
    */

    return collector;
  }, []);
  const permsMatchingKeys_sortedByPriority = permsMatchingKeys.sort((a, b) => b._priority - a._priority);
  const permsMatchingKeyValues_ignoringArrays = _lodash.default.filter(permsMatchingKeys_sortedByPriority, perm => {
    const noMatchKeys = _lodash.default.reduce(_lodash.default.omit(perm, ['_priority']), (diff, value, key) => {
      if (value == '*' ||
      //# only need to check if checkForPerm has the property if the permission isn't '*'
      checkForPerm.hasOwnProperty(key) && (_lodash.default.isArray(value) || value == checkForPerm[key])) {
        return diff;
      }
      return _lodash.default.assign({}, diff, {
        [key]: value
      });
    }, {});
    return _lodash.default.isEmpty(noMatchKeys) ? true : false;
  });

  //# since permsMatchingKeyValues_ignoringArrays is sorted by priority order, the first group of highest priority
  //# permissions MUST match
  //# EXAMPLE:
  //#     [{action: '*', model:[], _priority:10}, {action:'get', model:'*', _priority:1}]
  //#     the first permission overrides the second, that means for NO models do we allow ANY actions

  if (permsMatchingKeyValues_ignoringArrays.length == 0) {
    return false;
  }
  const priorityToCheck = permsMatchingKeyValues_ignoringArrays[0]._priority;
  const matchingPerms = _lodash.default.filter(permsMatchingKeyValues_ignoringArrays, perm => {
    const noMatchKeys = _lodash.default.reduce(_lodash.default.omit(perm, ['_priority']), (diff, value, key) => {
      const checkForPerm_atKey_asArr = [].concat(checkForPerm[key]);
      const value_asArr = [].concat(value);
      if (value == '*' || checkForPerm.hasOwnProperty(key) &&
      /*
      (
          //_.isArray(value)
          //&& _.find(value, testVal => testVal == checkForPerm_atKey) != undefined
      )
      || value == checkForPerm[key]
      */
      _lodash.default.intersectionWith(value_asArr, checkForPerm_atKey_asArr, (valA, valB) => valA == valB).length) {
        return diff;
      }
      return _lodash.default.assign({}, diff, {
        [key]: value
      });
    }, {});
    return _lodash.default.isEmpty(noMatchKeys) && priorityToCheck == perm._priority ? true : false;
  });

  //# NOTE: matchingPerms WILL return an array of perms that match IF we have
  //# {id:[], template: '*'} AND {id:[], template:''}
  //# we need to sort them by priority order then CHECK for best match and see if it's applicable

  return _lodash.default.isEmpty(matchingPerms) ? false : true;
}
function raw_idsInRubyPermissionsFromUser_forPermJSON(user, permJSON) {
  const needlePerm = JSON.parse(permJSON);
  const needlePermKeys = Object.keys(needlePerm);
  if (needlePermKeys.length == 0) {
    //# if the needlePerm is empty, don't allow anything to return
    return [];
  }

  //# only get ids for action:* or action:'get' or action:['get']
  const foundPerms = raw_idLimitedPermissionsFromUser_forPermJSON(user, permJSON);
  const idsFromFoundPerms = _lodash.default.reduce(foundPerms, (collector, perm) => {
    let newCollector = collector;
    if (perm.id) {
      if (!_lodash.default.isArray(newCollector)) {
        newCollector = [];
      }
      newCollector = newCollector.concat(perm.id);
    }
    return newCollector;
  }, undefined);
  return idsFromFoundPerms;
}
function raw_idLimitedPermissionsFromUser_forPermJSON(user, permJSON) {
  const needlePerm = JSON.parse(permJSON);
  const needlePermKeys = Object.keys(needlePerm);
  if (needlePermKeys.length == 0) {
    //# if the needlePerm is empty, don't allow anything to return
    return [];
  }
  const rubyRole = typeof user.rubyRole == 'function' ? user.rubyRole().toJSON() : user.rubyRole;
  const rubyPermissions = rubyRole.ruby_permissions;
  const additionalRubyPermissions = _lodash.default.get(user, 'additional_ruby_permissions', []);
  const allRubyPermissions = rubyPermissions.concat(additionalRubyPermissions);

  //# only get ids for action:* or action:'get' or action:['get']
  const foundPerms = _lodash.default.filter(allRubyPermissions, perm => {
    const permMatches = _lodash.default.reduce(needlePerm, (isMatch, needlePermValue, needlePermKey) => {
      if (isMatch == false) {
        return isMatch;
      }
      const needlePermValue_asArr = [].concat(needlePermValue);
      const permValue = _lodash.default.get(perm, needlePermKey);
      const permValue_asArr = [].concat(permValue);
      const additionalMatch = permValue == '*' || _lodash.default.intersectionWith(permValue_asArr, needlePermValue_asArr, (valA, valB) => valA == valB).length;
      return isMatch && additionalMatch;
    }, true);
    return permMatches;
  });
  const filteredFoundPerms = foundPerms.filter(perm => perm.hasOwnProperty('id'));
  return filteredFoundPerms;
}
function doesUserMatchPermissionObject(userObject, permissionsObjectArray) {
  return _lodash.default.every(permissionsObjectArray.map(perm => boolean_fromPermissionObject_andUser(perm, userObject)));
}