// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArrayList__DragHandleIcon___s0XOh {
    cursor: move;
    position: relative;
}

.ArrayList__Placeholder___MzeUt {
    /*
    background: #e8e8e8;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    */
    border-radius: 16px;
}

.ArrayList__ReactGridLayout___A0HTf .react-grid-item {
    width: 100% !important;
}

.ArrayList__chip___KxGlT .buttons > *{
    margin-left: 0px!important;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-array-map/src/client/reactComponents/ArrayList.cssModule"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;;;;;;;KAOC;IACD,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".DragHandleIcon {\n    cursor: move;\n    position: relative;\n}\n\n.Placeholder {\n    /*\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    */\n    border-radius: 16px;\n}\n\n.ReactGridLayout :global(.react-grid-item) {\n    width: 100% !important;\n}\n\n.chip :global(.buttons > *){\n    margin-left: 0px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DragHandleIcon": `ArrayList__DragHandleIcon___s0XOh`,
	"Placeholder": `ArrayList__Placeholder___MzeUt`,
	"ReactGridLayout": `ArrayList__ReactGridLayout___A0HTf`,
	"chip": `ArrayList__chip___KxGlT`
};
export default ___CSS_LOADER_EXPORT___;
