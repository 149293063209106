"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Url = _interopRequireDefault(require("./Url"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    feSettingsSelector,
    formComponent
  } = selfModule.getDependencies();

  //# figure out the toHTML ahead of time
  const hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const wwwSettings = _lodash.default.pick(feSettingsSelector(state), ["WWW.INIT.SSL_ONLY", "WWW_SERVER", "WWW_SSL_SERVER"]);
    const wwwPrefix = wwwSettings["WWW.INIT.SSL_ONLY"] ? wwwSettings["WWW_SSL_SERVER"] : wwwSettings["WWW_SERVER"];
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedProps = _extends({}, ownProps, mixinFieldProps, selfModule.getProps(true), {
      id: selfModule.getID(),
      default_host: wwwPrefix
    }, selfModule.props.key ? _lodash.default.pick(selfState.fields[selfModule.props.key], ['value', 'userModifiedTimestamp']) : {}, formattedError, hydratedToHTML ? {
      toHTML: hydratedToHTML
    } : {});
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      delegateActions: {
        pushNotification: formComponent.pushNotification.bind(formComponent)
      }
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Url.default);
}