"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const React = require('react');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');
const CONSTANTS = require('../common/constants');
const componentName = CONSTANTS.COMPONENT_NAME;
const action = require('./action');
const reducer = require('./reducer');
const middleware = require('./middleware');

//# Note: this function should only use here
//# if there are other places that need this functionality 
//# please discuss with the rubyapps team
function importAllFiles(r) {
  return r.keys().reduce((acc, key) => {
    acc[key.substring(2, key.lastIndexOf('.'))] = r(key);
    return acc;
  }, {});
}
const coreListerConfigs = importAllFiles(require.context('../../../../common/listers/', false, /\.js$/));

//# Mixins
const accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
const RCListerConfigs = RubyComponent.createClass({
  mixins: [accessControlMixin, require('./mixin')],
  propTypes: {
    formsId: PropTypes.string,
    augmentors: PropTypes.shape({
      listerConfig: PropTypes.arrayOf(PropTypes.func)
    }),
    defaultListerConfig: PropTypes.object
  },
  componentName: componentName,
  action: action,
  reducer: reducer,
  middleware: middleware,
  getDefaultProps: function () {
    return {
      formsId: RubyComponentForms__CONSTANTS.COMPONENT_NAME,
      defaultListerConfig: CONSTANTS.DEFAULT_CONFIG
    };
  },
  dependencies: function () {
    const root = this.getRoot();
    const formsComponent = root.findDescendentByID(this.props.formsId);
    return {
      formsComponent
    };
  }

  //# == Utility Methods =========================================//
  ,
  listerConfigsByKey: function () {
    //# get listerConfigs from template
    const {
      formsComponent
    } = this.getDependencies();
    const templatesByKey = formsComponent.getState();
    const templateListerConfigs = _lodash.default.reduce(templatesByKey, (collector, template, templateKey) => {
      const templateListerConfig = template.listerConfig;
      if (templateListerConfig) {
        collector[templateKey] = templateListerConfig;
      }
      return collector;
    }, {});
    return _extends({}, coreListerConfigs, templateListerConfigs);
  },
  listerConfigForKey: function (key, callingListerModule) {
    const listerConfigsByKey = this.listerConfigsByKey();
    const callingListerModule__listerConfig = _lodash.default.get(callingListerModule, 'props.listerConfig');
    const rawConfig = listerConfigsByKey[key] || callingListerModule__listerConfig || this.props.defaultListerConfig;
    const augmentedConfig = this.getAugmentedListerConfig(rawConfig, key, callingListerModule);
    return augmentedConfig;
  },
  getAugmentedListerConfig: function (listerConfig, key, callingListerModule) {
    const {
      formsComponent
    } = this.getDependencies();
    const template = formsComponent.getAugmentedTemplateForKey(key, undefined, callingListerModule);
    const ctx = {
      template
    };
    const mixins = this.getMixins();
    const augmentedListerConfigFns = [].concat(mixins.filter(mixin => mixin.hasOwnProperty('augmentedListerConfig')).map(mixin => mixin.augmentedListerConfig));
    const clonedListerConfigToAugment = _lodash.default.cloneDeep(listerConfig);
    const augmentedListerConfig = _lodash.default.reduce(augmentedListerConfigFns, (result, augmentor) => {
      return augmentor.call(this, result, key, callingListerModule, ctx);
    }, clonedListerConfigToAugment);
    return augmentedListerConfig;
  }
});
module.exports = RCListerConfigs;