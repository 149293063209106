// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mce-tinymce.mce-container {
    overflow: hidden;
}
.mce-tinymce .mce-toolbar-grp {
    height: auto;
}

/* This is only for */
.reactTinyMCE_hidden:first-child {
    margin-top: -3px;
}
.reactTinyMCE_hidden {
    height: 0px!important;
    overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/local_modules/react-tinymce/src/components/TinyMCE.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;;AAEA,qBAAqB;AACrB;IACI,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,gBAAgB;AACpB","sourcesContent":["\n.mce-tinymce.mce-container {\n    overflow: hidden;\n}\n.mce-tinymce .mce-toolbar-grp {\n    height: auto;\n}\n\n/* This is only for */\n.reactTinyMCE_hidden:first-child {\n    margin-top: -3px;\n}\n.reactTinyMCE_hidden {\n    height: 0px!important;\n    overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
