"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash"));
var _ConnectorMappersGenerator = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator"));
var _RichTextEditor = _interopRequireDefault(require("./RichTextEditor"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    mapStateToProps: defaultMapStateToProps
  } = _ConnectorMappersGenerator.default.call(this, _RichTextEditor.default);
  const {
    selfSelector,
    currentUserActions
  } = selfModule.getDependencies();

  //# figure out the toHTML ahead of time
  const hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
  const hydratedConfig = this.hydratedConfigWithChildrenReferences();
  const mergedBodyClass = hydratedConfig.body_class ? `${hydratedConfig.body_class} rte ${this.props.body_class || ''}` : `rte ${this.props.body_class || ''}`;
  hydratedConfig.body_class = _lodash.default.uniq(mergedBodyClass.split(' ')).join(' ');
  //# TODO: 20170714 - need to review how we merge these bodyclasses, but it's good enough for now

  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const formattedErrorMessage = _lodash.default.get(formattedError, 'error.message', '');
    const hasWordErrors = (_lodash.default.get(formattedError, 'error.wordsToHighlight') || []).length;
    const hasLinkErrors = (_lodash.default.get(formattedError, 'error.linksToHighlight') || []).length;
    const hydratedProps = selfModule.getProps(true);
    const mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
    const mappedProps = _extends({}, ownProps, mixinFieldProps, mappedDefaultProps, {
      id: selfModule.getID(),
      fieldKey: selfModule.props.key //key is not exposed to the react component
      ,
      config: hydratedConfig,
      hasWordErrors
    }, _lodash.default.pick(hydratedProps, ['keepToolbarOpen']), selfModule.props.key ? _lodash.default.pick(selfState.fields[selfModule.props.key], ['value', 'error', 'userModifiedTimestamp']) : {}, formattedError, hydratedToHTML ? {
      toHTML: hydratedToHTML
    } : {});
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      delegateActions: {
        currentUser: (0, _redux.bindActionCreators)(currentUserActions, dispatch)
      }
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_RichTextEditor.default);
}