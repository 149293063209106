// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../../ruby-styles/src/client/cssModules/field-base.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TokenTagger__wrapper___horMb {
}

.TokenTagger__primaryText___UcSXW {
    overflow: hidden;
    text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-repeater/src/client/reactComponents/TokenTagger/TokenTagger.cssModule"],"names":[],"mappings":"AAAA;AAEA;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".wrapper {\n    composes: wrapper from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n\n.primaryText {\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TokenTagger__wrapper___horMb ${___CSS_LOADER_ICSS_IMPORT_0___.locals["wrapper"]}`,
	"primaryText": `TokenTagger__primaryText___UcSXW`
};
export default ___CSS_LOADER_EXPORT___;
