// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TFA__infoParagraph___ugmga {
    margin-top: 15px;
    margin-bottom: 15px;
}

.TFA__infoList___ZZfpz {
    margin-top: 15px;
    margin-bottom: 15px;
}



.TFA__secretRow___gt1Vj {
    margin-top: 15px;
    margin-bottom: 15px;
}

.TFA__secretColumn___yAjxh {
    width: 256px;
    height: 256px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TFA__secretQRCode___HtLs6 {
    position: absolute;
}

.TFA__plainSecret___FrjVw {
    margin-top: 15px;
    margin-bottom: 15px;
}

`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-TFA/src/client/reactComponents/TFA.cssModule"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;;;AAIA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".infoParagraph {\n    margin-top: 15px;\n    margin-bottom: 15px;\n}\n\n.infoList {\n    margin-top: 15px;\n    margin-bottom: 15px;\n}\n\n\n\n.secretRow {\n    margin-top: 15px;\n    margin-bottom: 15px;\n}\n\n.secretColumn {\n    width: 256px;\n    height: 256px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.secretQRCode {\n    position: absolute;\n}\n\n.plainSecret {\n    margin-top: 15px;\n    margin-bottom: 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoParagraph": `TFA__infoParagraph___ugmga`,
	"infoList": `TFA__infoList___ZZfpz`,
	"secretRow": `TFA__secretRow___gt1Vj`,
	"secretColumn": `TFA__secretColumn___yAjxh`,
	"secretQRCode": `TFA__secretQRCode___HtLs6`,
	"plainSecret": `TFA__plainSecret___FrjVw`
};
export default ___CSS_LOADER_EXPORT___;
