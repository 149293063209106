// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/toolbar.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header__Toolbar___m3tgM {
    padding: 6px 24px 4px 24px!important;
}

.Header__ToolbarGroup___rM6B7 {
    max-width: 100%;
}

.Header__ToolbarGroup__left___NrWn4 {
    flex-grow: 1;
}

.Header__ToolbarGroup_rest___L9Z6N {
    max-width: calc(100% + 24px);
    flex-grow: 1;
    justify-content: flex-end!important;
    flex-wrap: wrap;
}

.Header__ToolbarTitle___eqAYi {
}
.Header__ToolbarTitle_mainLabel___kdr1j {
    max-width: 100%;
    padding-right: 0!important;
}
.Header__wrappingSpan___oNaSF {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}
.Header__ToolbarTitle_ParentLink___cMSYM {
    max-width: 70%;
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Header__ToolbarTitle_Edit___KgazD {
}
.Header__ToolbarTitle_span___Q2ZmA {
}
.Header__Toolbar_childSpacing___fXhFW {
    margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-header/src/client/reactComponents/Header.cssModule"],"names":[],"mappings":"AAAA;IAEI,oCAAoC;AACxC;;AAEA;IAEI,eAAe;AACnB;;AAEA;IAEI,YAAY;AAChB;;AAEA;IAEI,4BAA4B;IAC5B,YAAY;IACZ,mCAAmC;IACnC,eAAe;AACnB;;AAEA;AAEA;AACA;IAEI,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,sBAAsB;IACtB,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;AACA;AACA;AACA;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".Toolbar {\n    composes: Toolbar from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n    padding: 6px 24px 4px 24px!important;\n}\n\n.ToolbarGroup {\n    composes: ToolbarGroup from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n    max-width: 100%;\n}\n\n.ToolbarGroup__left {\n    composes: ToolbarGroup from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n    flex-grow: 1;\n}\n\n.ToolbarGroup_rest {\n    composes: ToolbarGroup;\n    max-width: calc(100% + 24px);\n    flex-grow: 1;\n    justify-content: flex-end!important;\n    flex-wrap: wrap;\n}\n\n.ToolbarTitle {\n    composes: ToolbarTitle from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n}\n.ToolbarTitle_mainLabel {\n    composes: ToolbarTitle_mainLabel from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n    max-width: 100%;\n    padding-right: 0!important;\n}\n.wrappingSpan {\n    max-width: 100%;\n    display: inline-block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: black;\n}\n.ToolbarTitle_ParentLink {\n    max-width: 70%;\n    display: inline-block;\n    vertical-align: bottom;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.ToolbarTitle_Edit {\n}\n.ToolbarTitle_span {\n}\n.Toolbar_childSpacing {\n    margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Toolbar": `Header__Toolbar___m3tgM ${___CSS_LOADER_ICSS_IMPORT_0___.locals["Toolbar"]}`,
	"ToolbarGroup": `Header__ToolbarGroup___rM6B7 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["ToolbarGroup"]}`,
	"ToolbarGroup__left": `Header__ToolbarGroup__left___NrWn4 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["ToolbarGroup"]}`,
	"ToolbarGroup_rest": `Header__ToolbarGroup_rest___L9Z6N Header__ToolbarGroup___rM6B7 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["ToolbarGroup"]}`,
	"ToolbarTitle": `Header__ToolbarTitle___eqAYi ${___CSS_LOADER_ICSS_IMPORT_0___.locals["ToolbarTitle"]}`,
	"ToolbarTitle_mainLabel": `Header__ToolbarTitle_mainLabel___kdr1j ${___CSS_LOADER_ICSS_IMPORT_0___.locals["ToolbarTitle_mainLabel"]}`,
	"wrappingSpan": `Header__wrappingSpan___oNaSF`,
	"ToolbarTitle_ParentLink": `Header__ToolbarTitle_ParentLink___cMSYM`,
	"ToolbarTitle_Edit": `Header__ToolbarTitle_Edit___KgazD`,
	"ToolbarTitle_span": `Header__ToolbarTitle_span___Q2ZmA`,
	"Toolbar_childSpacing": `Header__Toolbar_childSpacing___fXhFW`
};
export default ___CSS_LOADER_EXPORT___;
